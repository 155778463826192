import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import axios from 'axios';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import { createAxiosResponseInterceptor } from './lib/axios/request';
import App from './App';


axios.defaults.baseURL = process.env.REACT_APP_API_URL;
createAxiosResponseInterceptor();

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        environment: 'production',
        dsn: 'https://f166665f9c42473fa43d7886ca57e249@o1321113.ingest.sentry.io/4504071352614912',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.1,
    });
}


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // Because of react.StrictMode in DEV env only all useEffetcs will be called twice https://stackoverflow.com/a/61897567
    // https://stackoverflow.com/a/43470639
    <React.StrictMode>
        <HashRouter>
            <App />
        </HashRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
