/* eslint-disable react/no-array-index-key, no-undef */

import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../../../components/common/PrimaryButton';
import useAutoFocus from '../../../../hooks/useAutoFocus';

export declare type ReperaterInput = { value: string };

interface Props {
    label: string,
    identifier: string,
    errorText?: string,
    valuesArr: ReperaterInput[],
    onChangeAction: (values: ReperaterInput[]) => void;
}

function ResumeInputRepeater({
    label, onChangeAction, identifier, valuesArr, errorText = '',
}: Props) {
    const [values, setValues] = useState<ReperaterInput[]>([]);

    const { inputRef: nameInputRef, focusInput } = useAutoFocus();

    const [newValue, setNewValue] = useState<string>('');

    useEffect(() => {
        if (!valuesArr.length) {
            return;
        }
        const newValues = valuesArr;
        setValues(newValues);
    }, [valuesArr]);


    const onAddClick = () => {
        const arr = [...values];
        arr.push({ value: newValue });

        setNewValue('');
        setValues(arr);
        onChangeAction(arr);
        focusInput();
    };

    const onChange = (value: string) => {
        setNewValue(value);
    };

    const onChangeExisting = (value: string, index: number) => {
        if (value.length > 255) {
            return;
        }
        const arr = [...values];
        arr[index].value = value;
        setValues(arr);
        onChangeAction(arr);
    };

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key !== 'Enter') {
            return;
        }
        if (!newValue) {
            return;
        }
        onAddClick();
    };

    const removeClick = (index: number) => {
        const arr = [...values];
        arr.splice(index, 1);
        setValues(arr);
        onChangeAction(arr);
        focusInput();
    };

    return (
        <>
            <div className="d-flex flex-column">
                <label className="defaultGreyText mb-4">{label}</label>
                <div className="align-items-center d-flex justify-content-between mb-3">
                    <input
                        ref={nameInputRef}
                        id={`input_${identifier}`}
                        onChange={(e) => onChange(e.target.value)}
                        className="editInput w-100 "
                        placeholder={label}
                        value={newValue}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <PrimaryButton
                        text="Add"
                        disabled={(newValue.length === 0)}
                        className="cancelBtn ms-3 cursor-pointer"
                        onClick={() => onAddClick()}
                    />
                </div>

                {/* Existing inputs */}
                {values.map((value, index) => (
                    <div className="align-items-center d-flex justify-content-between mb-3" key={`key_${index}`}>
                        <input
                            value={value.value}
                            id={`${identifier}_${index}`}
                            onChange={(e) => onChangeExisting(e.target.value, index)}
                            className="editInput w-100 "
                        />
                        <PrimaryButton
                            text="Remove"
                            className="cancelBtn removeBtn ms-3 cursor-pointer"
                            onClick={() => removeClick(index)}
                        />
                    </div>
                ))}
            </div>
            {errorText && (
                <small className="error-text">{errorText}</small>
            )}
        </>
    );
}

export default ResumeInputRepeater;