import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout/Layout';
import CandidateItem from './CandidateItem';
import Input from '../../../components/common/Input';
import { CandidatesList, CandidatesNumbers } from '../ts/candidates.interface';
import CandidatesService from '../services/candidates.service';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { useDebounce } from '../../../hooks/useDebounce';
import CandidateNoResults from './CandidateNoResults';
import Pagination from '../../../components/table/Pagination';


interface Props {
    className?: string;
}

function CandidatesIndex({ className = '' }: Props) {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const [candidatesLoading, setCandidatesLoading] = useState<boolean>(false);
    const [candidates, setCandidates] = useState<CandidatesList>({
        count: 0,
        next: null,
        previous: null,
        results: [],
    });

    const [numbersLoading, setNumbersLoading] = useState<boolean>(false);
    const [numbers, setNumbers] = useState<CandidatesNumbers>({
        total: 0,
        available: 0,
        archived: 0,
    });

    // eslint-disable-next-line default-param-last
    const getCandidates = async (search = '', nextPageUrl?: string | null, previousPageUrl?: string | null): Promise<void> => {
        setCandidatesLoading(true);
        CandidatesService.getCandidates({ search, nextPageUrl, previousPageUrl }).then((candidatesResponse) => {
            setCandidates(candidatesResponse);
            setCandidatesLoading(false);
        });
    };

    const getNumbers = async (): Promise<void> => {
        setNumbersLoading(true);
        CandidatesService.getNumbers().then((numbersResponse) => {
            setNumbers(numbersResponse);
            setNumbersLoading(false);
        });
    };
    const searchValue = useDebounce(searchQuery);

    const onNextPageClick = () => {
        getCandidates(searchValue, candidates?.next, null);
    };

    const onPreviousPageClick = () => {
        getCandidates(searchValue, null, candidates?.previous);
    };

    useEffect(
        () => {
            if (searchValue) {
                getCandidates(searchValue);
            } else {
                getCandidates();
            }
        },
        [searchValue], // Only call effect if debounced search term changes
    );


    useEffect(() => {
        getCandidates().then(() => {
            getNumbers();
        });
    }, []);

    return (
        <Layout>
            <div className="content line mobilePadding">
                <div>
                    <div
                        className="align-items-center d-flex flex-row flex-sm-row flex-wrap justify-content-sm-between justify-content-start mb-4 mb-sm-5 mt-4 mt-sm-4"
                    >
                        <div className="d-flex ">
                            <p className="darkGrey mb-1 profileName candidatesProfileName">
                                Candidates
                            </p>
                            <LoadingSpinner isLoading={candidatesLoading} className="mt-2" />
                        </div>
                        {/* <div */}
                        {/*    className="d-flex fullWithMobile justify-content-center justify-content-sm-start mt-3 mt-sm-0" */}
                        {/* > */}
                        {/*    <div className="mt-3 mt-sm-0"> */}
                        {/*        <Link to="/candidates/create" className="addCandidateBtn"> */}
                        {/*            Add new Candidate */}
                        {/*        </Link> */}
                        {/*    </div> */}
                        {/* </div> */}
                    </div>
                </div>
                <div>
                    <hr />
                    <div className="d-flex d-sm-flex flex-column flex-sm-row mb-4 mt-4">
                        <div className="borderRight">
                            <span className="bold candidatesNumber darkGrey">{numbers.total}</span>
                            <span className="defaultGreyText ms-3">Total candidates</span>
                        </div>
                        <div className="borderRight">
                            <span className="bold candidatesNumber darkGrey">{numbers.available}</span>
                            <span className="defaultGreyText ms-3">Available candidates</span>
                        </div>
                        <div className="borderRight">
                            <span className="bold candidatesNumber darkGrey">{numbers.archived}</span>
                            <span className="defaultGreyText ms-3">Archived candidates</span>
                        </div>
                        <LoadingSpinner isLoading={numbersLoading} />
                    </div>
                    <hr />
                </div>
                <div
                    className="align-items-sm-center align-items-start d-flex d-sm-flex flex-column flex-sm-row justify-content-sm-between justify-content-start mb-5 mb-sm-3 mt-4 mt-sm-4"
                >
                    <div>
                        <h3 className="darkGrey overview">Overview</h3>
                        <p className="defaultGreyText">Some candidates may apply</p>
                    </div>
                    <div className="fullWithMobile">
                        <div className="th-loading d-flex">
                            <LoadingSpinner isLoading={candidatesLoading} />
                            <Input
                                wrapperClassName="fullWithMobile"
                                label="Search"
                                value={searchQuery}
                                className="candidatesSearched"
                                name="search"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search candidates..."
                            />
                        </div>
                    </div>

                </div>
                <div>
                    <div className="row">
                        {(candidatesLoading && candidates?.count === 0) && (
                            <div>
                                <LoadingSpinner isLoading={candidatesLoading} />
                            </div>
                        )}
                        {candidates?.results.map((candidate) => (
                            <CandidateItem key={candidate.id} candidate={candidate} />
                        ))}

                        {candidates?.count === 0 && (
                            <CandidateNoResults />
                        )}
                    </div>
                    <Pagination
                        count={candidates?.count ?? 0}
                        previous={candidates?.previous ?? null}
                        next={candidates?.next ?? null}
                        isLoading={candidatesLoading}
                        onNextClick={onNextPageClick}
                        onPreviousClick={onPreviousPageClick}
                    />
                </div>
            </div>


        </Layout>
    );
}

export default CandidatesIndex;