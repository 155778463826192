import React from 'react';


interface Props {
    isLoading?: boolean;
}

function TableLoading({ isLoading = false }: Props) {
    if (!isLoading) {
        return null;
    }
    return (
        <div>
            <div className="align-items-center d-flex justify-content-center mt-4 pb-4 tableLoader">
                <img src="/logo.svg" className="blogo logoAnimation" alt="Table loader" />
            </div>
            <div className="borderBtm" />
        </div>
    );
}

export default TableLoading;