/* eslint-disable no-undef */

import { RefObject, useEffect, useState } from 'react';


function useOnClickOutside(ref: RefObject<HTMLElement>): boolean {
    const [isClickedOutside, setIsClickedOutside] = useState<boolean>(false);

    // If pressed key is our target key then set to true
    function handleClickOutside(event: { target: Node | null; }): void {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsClickedOutside(true);
        } else {
            setIsClickedOutside(false);
        }
    }

    useEffect(() => {
        // Bind the event listener
        // @ts-ignore
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            // @ts-ignore
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    /* Whenever we updatae this state to true, we want to reset it again to initial state of not clicked */
    useEffect(() => {
        if (isClickedOutside) {
            setIsClickedOutside(false);
        }
    }, [isClickedOutside]);

    return isClickedOutside;
}


export default useOnClickOutside;