/* eslint-disable guard-for-in,no-restricted-syntax,no-undef */
import {
    AvailableCandidate,
    AvailableManager,
    InsertCompanyDto,
    UpdateCompanyDto,
    UpdateCompanyForm,
} from '../ts/company-service.interface';
import {
    sendDeleteRequest,
    sendGetRequest,
    sendPostRequestWithFile,
    sendPutRequest,
    sendPutRequestWithFile,
} from '../../../lib/axios/request';
import {
    CompaniesDo, CompaniesDto, CompaniesIndexProps, CompanyDo, CompanyDto, 
} from '../ts/comapnies-index.interface';


const CompanyService = {

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-e97cc2be-6f50-495b-b2f9-e2f07e8e28f2 | Link}
     */
    async index({ search, pageUrl }: CompaniesIndexProps): Promise<CompaniesDo> {
        let queryString = '';
        if (search) {
            queryString += `search=${search}`;
        }

        const fullReqUri = (pageUrl ?? `/companies/?order_by=name&order_direction=asc&${queryString}`);
        const companies = await sendGetRequest(`${fullReqUri}`) as unknown as CompaniesDto;

        companies?.results.forEach((item) => {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            item.managers_label = this.getManagerLabel(item.managers);
            return item as CompanyDo;
        });

        return companies as unknown as CompaniesDo;
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-f817df36-8443-4c86-aee4-b4a3e06a5e3f | Link}
     */
    async delete(id: string): Promise<any> {
        return await sendDeleteRequest(`/companies/${id}/`, null);
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-650737e5-f944-4c53-8f8d-c6b04d96a0b1 | Link}
     * @Description Get Company By ID
     */
    async show(id: string): Promise<CompanyDto> {
        return await sendGetRequest(`/companies/${id}/`);
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-41ce4d84-5ce4-4508-9e76-f843a42fbb6b | Link}
     */
    async insert(data: InsertCompanyDto): Promise<any> {
        const formData = new FormData();
        for (const key in data) {
            if (key === 'image' && !data[key]) {
                // eslint-disable-next-line no-continue
                continue;
            }
            // @ts-ignore
            formData.append(key, data[key]);
        }
        return await sendPostRequestWithFile('/companies/', formData);
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-ccc84cdc-81d1-41c2-864d-da0dfd294bd6 | Link}
     */
    async update(data: UpdateCompanyDto): Promise<any> {
        return await sendPutRequestWithFile(`/companies/${data.id}/`, this.prepareFormData(data));
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-addb0edb-85c5-4da6-a663-6305e7ad0308 | Link}
     */
    async updateWithUsers(data: UpdateCompanyForm): Promise<any> {
        return await sendPutRequest(`/companies/${data.id}/`, { ...data });
    },

    prepareFormData(data: object): FormData {
        const formData = new FormData();
        for (const key in data) {
            // @ts-ignore
            if (!data[key]) {
                // eslint-disable-next-line no-continue
                continue;
            }
            // @ts-ignore
            formData.append(key, data[key]);
        }
        return formData;
    },

    getManagerLabel(managers: Array<string>): string {
        if (!managers.length) {
            return '-';
        }
        return managers.join(', ');
    },

    async getAvailableCandidates(companyId: string): Promise<AvailableCandidate[]> {
        return await sendGetRequest(`/companies/${companyId}/available-candidates/`);
    },

    async getAvailableManagers(companyId: string): Promise<AvailableManager[]> {
        return await sendGetRequest(`/companies/${companyId}/available-managers/`);
    },
};
export default CompanyService;