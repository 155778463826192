import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { StoreContext } from '../../../store.context';
import { LoginFailResponse } from '../ts/auth_interfaces';
import useUser from '../hooks/useUser';
import LoadingOverlay from '../../../components/layout/LoadingOverlay';
import PrimaryButton from '../../../components/common/PrimaryButton';
import LoginLayout from '../../../components/layout/LoginLayout';

interface FormErrorProps {
    error?: string,
    username: string,
    password: string,
}

function LoginComponent() {
    const { authStore } = useContext(StoreContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const { user, isUserLoading } = useUser();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [formErrors, setFormErrors] = useState<FormErrorProps>({
        error: '',
        username: '',
        password: '',
    });

    const handleFailedLogin = (errors: LoginFailResponse) => {
        let nonFieldErr = '';
        if (errors?.non_field_errors) {
            const index = errors?.non_field_errors.findIndex((f) => f !== '0');
            nonFieldErr = errors?.non_field_errors[index];
        }
        setFormErrors({
            error: nonFieldErr,
            username: (errors.username?.length ? errors.username[0] : ''),
            password: (errors.password?.length ? errors.password[0] : ''),
        });
    };

    const loginAction = () => {
        setIsLoading(true);
        authStore.login({ username, password }).then((response) => {
            setIsLoading(false);
            if (!response.token) {
                handleFailedLogin(response as unknown as LoginFailResponse);
                return;
            }
            navigate(0);
        });
    };


    useEffect(() => {
        if (!isUserLoading && user) {
            navigate('/');
        }
    }, [user]);

    if (isUserLoading) {
        return (<LoadingOverlay />);
    }
    return (
        <LoginLayout>
            <div className="bold loginContainer">
                <div className="align-items-center d-flex justify-content-center mb-3 ovalProf">
                    <i className="fa-unlock-alt fas" />
                </div>
                <h1 className="loginHeading">
                    Start your
                    <br />
                    journey with us.
                </h1>
                <h3 className="subtitle">
                    The world&#39;s best way to manage
                    <br />
                    and share
                    your resume
                </h3>
                {formErrors.error && (
                    <div>
                        <h3 className="error-text">{formErrors.error}</h3>
                    </div>
                )}
                <div className="d-flex flex-column mb-4">
                    <label className="loginLabel mb-2">Email</label>
                    <input
                        onChange={(event) => setUsername(event.target.value)}
                        placeholder="Enter your username"
                        value={username}
                        type="username"
                        name="username"
                        className="loginInput"
                    />
                    {formErrors?.username && (
                        <small className="error-text">{formErrors?.username}</small>
                    )}
                </div>
                <div className="d-flex flex-column">
                    <label className="loginLabel mb-2">Password</label>

                    <input
                        className="loginInput"
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        type="password"
                        name="password"
                        placeholder="**********"
                    />
                    {formErrors?.password && (
                        <small className="error-text">{formErrors?.password}</small>
                    )}
                </div>
                <PrimaryButton
                    isLoading={isLoading}
                    text="SIGN IN"
                    onClick={() => loginAction()}
                    className="loginBtn"
                />
                <Link to="/forgot-password" className="forgotPassword">Forgot password?</Link>
                <Link to="/request-access" className="forgotPassword">Request Access</Link>
            </div>
        </LoginLayout>
    );
}

const Login = observer(LoginComponent);
export default Login;
