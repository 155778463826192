import React from 'react';
import LoadingSpinner from '../common/LoadingSpinner';


interface Props {
    className?: string;
    onlyArrows?: boolean;
    next: string | null | undefined,
    previous: string | null | undefined,
    count: number | undefined,
    onNextClick?: (link: string) => void,
    onPreviousClick?: (link: string) => void,
    isLoading?: boolean,
}

function Pagination({
    className = '',
    onlyArrows = true,
    isLoading = false,
    next = null,
    previous = null,
    count = 0,
    onNextClick = () => null,
    onPreviousClick = () => null,
}: Props) {
    return (
        <div className="d-flex justify-content-center">
            <div className="paginationBox">
                {previous && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <span className="cursor-pointer" onClick={() => onPreviousClick(previous)}>
                        <i className="fa-arrow-left fas paginationArrow" />
                    </span>
                )}
                {next && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <span className="cursor-pointer" onClick={() => onNextClick(next)}>
                        <i className="fa-arrow-right fas paginationArrow" />
                    </span>
                )}
                {isLoading && (
                    <LoadingSpinner isLoading={isLoading} />
                )}
            </div>
        </div>
    );
    // UNSUPPORTED
    // return (
    //     <div className="d-flex justify-content-center">
    //         <div className="paginationBox">
    //             <a href="#!"><i className="fa-arrow-left fas paginationArrow" /></a>
    //             <a
    //                 href="#!"
    //                 className="active number"
    //             >
    //                 1
    //             </a>
    //             <a href="#!" className="number">2</a>
    //             <a
    //                 href="#!"
    //                 className="number"
    //             >
    //                 3
    //             </a>
    //             <a
    //                 href="#!"
    //             >
    //                 <i className="fa-arrow-right fas paginationArrow" />
    //             </a>
    //         </div>
    //     </div>
    // );
}

export default Pagination;