import { sendPostRequest } from '../lib/axios/request';
import { LoginRequest, LoginResponse } from '../features/authentication/ts/auth_interfaces';

export class AuthService {
    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-70f806e1-8c5d-4102-9ffb-00b6054866da | Link}
     */
    async login(loginRequest: LoginRequest): Promise<LoginResponse> {
        const { response } = await sendPostRequest(`${process.env.REACT_APP_API_URL}/get-token/`, loginRequest);
        return response.data;
    }

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-70f806e1-8c5d-4102-9ffb-00b6054866da | Link}
     */
    async logout(): Promise<void> {
        // Currently backend has no support for logout :)
    }
}
