import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Candidate } from '../ts/candidates.interface';
import PrimaryButton from '../../../components/common/PrimaryButton';
import ImageHolder from '../../../components/user/ImageHolder';
import CandidatesService from '../services/candidates.service';


interface Props {
    className?: string;
    candidate: Candidate;
}

function CandidateItem({ className = '', candidate }: Props) {
    const navigate = useNavigate();
    const goToDetails = () => {
        navigate(`/candidates/${candidate.id}`);
    };

    const statusIconClass = useMemo(() => CandidatesService.getCandidateAvailabilityClass(candidate.usermeta.availability.id), []);

    return (
        <div className="col-md-auto col-sm-6 pb-2 pb-sm-2 pe-sm-2 pt-2 pt-sm-2">
            <div className="candidatesCard p-2">
                <div className="align-items-center d-flex justify-content-between">
                    <span className="candidateNUmber">{candidate.user}</span>
                    <div className={`candidate-status ${statusIconClass}`} />
                </div>
                <div className="d-flex justify-content-center ">
                    <ImageHolder
                        className="ovalImg"
                        image={candidate.usermeta.image}
                        name={candidate.usermeta.full_name}
                    />
                </div>
                <div className="align-items-center d-flex flex-column justify-content-center">
                    <span className="candidatesName mt-3 text-center">{candidate.usermeta.full_name}</span>
                    <span
                        className="defaultGreyText mt-2 text-center line-clamp-1 candidate-box-name"
                    >
                        {candidate.name}
                    </span>
                    <PrimaryButton text="Details" onClick={() => goToDetails()} className="detailsBtn mt-3" />
                </div>
            </div>
        </div>
    );
}

export default CandidateItem;