/* eslint-disable no-undef */
import { MutableRefObject, useEffect, useRef } from 'react';

const useAutoFocus = () => {
    const inputRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;

    const focusInput = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    };
    
    useEffect(() => {
        focusInput();
    }, []);

    return { inputRef, focusInput };
};

export default useAutoFocus;