import React, { useEffect, useRef, useState } from 'react';
import useKeyPress from '../../../hooks/useKeyPress';
import notifications from '../../../utils/notifications';
import { GENERAL_ERROR_MESSAGE } from '../../../utils/constants';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import PrimaryButton from '../../../components/common/PrimaryButton';


interface Props {
    className?: string,
    link: string,
    onClose: () => void,
}

function MagicLinkModal({ className = '', link = '', onClose }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const isEscapeClicked = useKeyPress('Escape');

    const modalRef = useRef(null);
    const isClickedOutside = useOnClickOutside(modalRef);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    useEffect(() => {
        if (isClickedOutside && isOpen) {
            closeModal();
        }
    }, [isClickedOutside]);

    useEffect(() => {
        if (!link) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    }, [link]);

    const openLink = () => {
        if (!link) {
            notifications.error(GENERAL_ERROR_MESSAGE);
            return;
        }
        // eslint-disable-next-line no-undef
        window.open(link, '_blank');
    };
    const copyToClipboard = () => {
        // eslint-disable-next-line no-undef
        navigator.clipboard.writeText(link);
        notifications.success('Magic link copied to clipboard.');
    };


    useEffect(() => {
        if (isEscapeClicked && isOpen) {
            closeModal();
        }
    }, [isEscapeClicked]);

    return (
        <div className={`customModalContainer ${isOpen ? '' : 'd-none'}`}>
            <div className="magicModal" ref={modalRef}>
                <div className="align-items-start blueBg d-flex justify-content-between p-3">
                    <h5 className="mb-0 text-center text-white">
                        Successfully generated magic link
                    </h5>
                    <button type="button" onClick={() => closeModal()} className="close-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            className="xmark cursor-pointer"
                        >
                            <path
                                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                            />
                        </svg>
                    </button>
                </div>
                <hr className="mb-0 mt-0" />
                <div className="pb-3 pe-3 ps-3 pt-4">
                    <a href={link} target="_blank" className="mb-0 text-body" rel="noreferrer">
                        {`${link.substring(0, 70)}.....`}
                    </a>
                </div>
                <hr className="mb-0 mt-0" />
                <div className="d-flex justify-content-end p-3">
                    <PrimaryButton
                        type="button"
                        className="cancelBtn"
                        onClick={() => copyToClipboard()}
                        text="Copy"
                    />
                    <PrimaryButton type="button" className="ms-3 saveBtn" onClick={() => openLink()} text="Open" />
                </div>
            </div>
        </div>
    );
}

export default MagicLinkModal;