import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import TimeHelper from '../../utils/TimeHelper';

export interface DatePickerSelectAction {
    date: Date | null,
    date_YYYY_MM_DD: string | null,
}

interface Props {
    startDateObj: Date | null;
    errorText?: string;
    required?: boolean;
    className?: string;
    label?: string;
    onChange: (obj: DatePickerSelectAction) => void;
    allowFutureDates?: boolean;
}

function DatePickerInput({
    startDateObj = null,
    className = '',
    errorText = '',
    label = 'Date',
    onChange,
    required = false,
    allowFutureDates = false,
}: Props) {
    const [selectedDate, setSelectedDate] = useState<Date | null>(startDateObj);

    const onChangeInternal = (date: Date | null) => {
        setSelectedDate(date);
        if (!date) {
            onChange({ date: null, date_YYYY_MM_DD: null });
            return;
        }
        onChange({ date, date_YYYY_MM_DD: TimeHelper.formatDateToYYYYMMDD(date) });
    };


    return (
        <>
            <label className={`editLabel mb-2 ${required ? 'required' : ''}`}>{label}</label>
            <div className={`${className}`}>
                <DatePicker
                    maxDate={(allowFutureDates ? null : addDays(new Date(), 0))}
                    yearDropdownItemNumber={50}
                    selected={selectedDate}
                    onChange={onChangeInternal}
                    locale="en"
                    placeholderText="dd/MM/yyyy"
                    dateFormat="dd/MM/yyyy"
                    todayButton="Today"
                    showYearDropdown
                    scrollableYearDropdown
                />
            </div>
            {errorText && (
                <span className="error-text">{errorText}</span>
            )}
        </>
    );
}

export default DatePickerInput;
