import { sendGetRequest } from '../lib/axios/request';

export interface Group {
    id: number,
    name: string
}

const GroupService = {
    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-1cb125a4-1713-4757-ab23-2ab80ce3377d | Link}
     */
    async getGroups(): Promise<Group[]> {
        return await sendGetRequest('/groups/');
    },
};

export default GroupService;
