import React from 'react';


interface Props {
    className?: string;
}

function TableNoResults({ className = '' }: Props) {
    return (
        <div>
            <div className="align-items-center d-flex justify-content-center mt-4 pb-4 tableLoader">
                <p className="darkGrey mb-1 ms-3">No result</p>
            </div>
            <div className="borderBtm" />
        </div>
    );
}

export default TableNoResults;