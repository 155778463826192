/* eslint-disable */
import React, { useEffect, useState } from 'react';
import AlertResolver from '../../utils/AlertResolver';

interface Props {
    identifier: string;
    text: string;
}

function InfoAlert({ identifier, text }: Props) {
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const onCloseAction = () => {
        AlertResolver.markAsRead(identifier);
        setShowAlert(false);
    };

    useEffect(() => {
        const isRead = AlertResolver.hasBeenRead(identifier);
        setShowAlert(!isRead);
    }, [identifier]);

    if (!showAlert) {
        return null;
    }

    return (
        <div className='infoAlert mt-5 mt-lg-5 mt-sm-5 pb-2 pe-3 ps-3 pt-2'>
            <div className='text-end'>
                <a onClick={() => onCloseAction()}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 320 512'
                        className='xmark'
                    >
                        {/*! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                        <path
                            d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z'
                        />
                    </svg>
                </a>
            </div>
            <div className='d-flex pe-2 ps-2'>
                <div>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                        className='circleInfo'
                    >
                        {/*! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                        <path
                            d='M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z'
                        />
                    </svg>
                </div>
                <div>
                    <p className='pe-3 ps-3'>
                        {text}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default InfoAlert;