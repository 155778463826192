/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes, { AvailableRoute, getAllowedRoutes } from '../config/routes';
import useUser from '../features/authentication/hooks/useUser';
import LoadingOverlay from '../components/layout/LoadingOverlay';
import NotFound from '../components/pages/404';

/*
* This is the route utility component used for generating
* routes and child routes it only requires routes array and basePath
*/
function MapAllowedRoutes() {
    const { user, userRole, isUserLoading } = useUser();

    const availRoutes = getAllowedRoutes(routes, userRole, user);
    if (isUserLoading) {
        return <LoadingOverlay />;
    }
    return (
        <Routes>
            {availRoutes.map((route: AvailableRoute) => {
                const {
                    path,
                    component: Component,
                    title,
                    permission,
                    ...rest
                } = route;
                return (
                    <Route
                        {...rest}
                        key={path}
                        path={`${path}`}
                        element={<Component />}
                    />
                );
            })}
            {/* IMPORTANT; redirects user to homepage if he goes to non-existing route. */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}


export default memo(MapAllowedRoutes);