import React from 'react';
import './App.css';
import './datepicker.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MapAllowedRoutes from './routes/MapAllowedRoutes';
import { PromptContextProvider } from './context/PromptContext';
import PromptModal from './components/common/PromptModal';
import './notification.css';

function App() {
    return (
        <PromptContextProvider>
            <PromptModal />
            <div className="App">
                <MapAllowedRoutes />
            </div>
            <ToastContainer
                position="top-right"
                autoClose={false}
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="light"
            />
        </PromptContextProvider>
    );
}

export default App;
