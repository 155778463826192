import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../features/authentication/hooks/useUser';
import Sidebar from './Sidebar';


interface Props {
    className?: string;
    children: React.ReactNode;
}

function Layout({ className = '', children }: Props) {
    const { user } = useUser();
    const [isMobileOpen, seIsMobileOpen] = useState<boolean>(false);

    const [customLogoSmall, setCustomLogoSmall] = useState<string | null>(null);
    const [customLogoLarge, setCustomLogoLarge] = useState<string | null>(null);

    useEffect(() => {
        const tmpCustomLogoSmall = process.env.REACT_APP_LOGO_URL_SMALL;
        const tmpCustomLogoLarge = process.env.REACT_APP_LOGO_URL_FULL;
        // const tmpCustomLogoLarge = '/assets/MorganCode-full.png';
        // const tmpCustomLogoSmall = '/assets/MorganCode-small.png';
        if (tmpCustomLogoSmall) {
            setCustomLogoSmall(tmpCustomLogoSmall);
        }
        if (tmpCustomLogoLarge) {
            setCustomLogoLarge(tmpCustomLogoLarge);
        }
    }, []);

    return (
        <div>
            <div className="headerbg sticky-header">
                <div className="align-items-center d-flex">
                    <Link to="/">
                        {(customLogoSmall && customLogoLarge) ? (
                            <>
                                <img
                                    alt="Logo"
                                    src={customLogoLarge}
                                    style={{ filter: 'invert(1)', height: '2.7rem' }}
                                    className="blogo d-sm-none d-none d-md-block"
                                />
                                <img
                                    alt="Logo"
                                    src={customLogoSmall}
                                    style={{ filter: 'invert(1)', height: '2.7rem' }}
                                    className="blogo d-md-none"
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    alt="Logo"
                                    src="/assets/Espelavo.svg"
                                    className="blogo d-sm-none d-none d-md-block"
                                />
                                <img
                                    alt="Logo"
                                    src="/assets/logo.svg"
                                    className="blogo d-md-none"
                                />
                            </>
                        )}
                    </Link>
                </div>
                <div className="align-items-center d-flex ">
                    <div className="me-3">
                        <p className="mb-1 user">{user?.full_name}</p>
                        <p className="manager mb-0">{user?.role_name}</p>
                    </div>
                    <button
                        onClick={() => seIsMobileOpen(!isMobileOpen)}
                        type="button"
                        className="d-lg-none h-auto navbar-dark navbar-toggler pe-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarTogglerDemo03"
                        aria-controls="navbarTogglerDemo03"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                </div>
            </div>

            <main>
                <div className="mainContentGrid">
                    <Sidebar isSidebarOpen={isMobileOpen} />
                    {children}
                </div>
            </main>
        </div>
    );
}

export default Layout;