import React from 'react';

function LoadingFunctionBox() {
    return (
        <div className="col-12 col-lg-4 col-md-4 col-sm-6 col-xl-auto d-flex justify-content-center p-3 ps-lg-2">
            <div
                className="align-items-center d-flex flex-column justify-content-center justify-content-md-center professionDiv"
            >
                <img alt="logo" src="/assets/logo.svg" width="20%" height="20%" className="logoAnimation" />

            </div>
        </div>
    );
}

export default LoadingFunctionBox;