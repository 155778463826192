import React, { useMemo } from 'react';
import UserHelper from '../../features/authentication/services/UserHelper';


interface Props {
    className?: string;
    initialsClassName?: string;
    image: string | null;
    name?: string;
}

function ImageHolder({
    className = '', image = null, name = 'C V', initialsClassName = '',
}: Props) {
    const userInitials = useMemo(() => UserHelper.getUserInitials(name), [name]);

    if (!image) {
        return (
            <div className={`align-items-center d-flex justify-content-center ovalProf ${initialsClassName}`}>
                <span>{userInitials}</span>
            </div>
        );
    }
    return (
        <div
            className={className}
            style={{ backgroundImage: `url(${image})` }}
        />
    );
}

export default ImageHolder;