/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Layout from '../../../components/layout/Layout';
import AccessService from '../services/access.service';
import { UserAccessCompany, UserAccessDto, UserAccessGroup } from '../ts/access.interfaces';
import notifications from '../../../utils/notifications';
import { GENERAL_SUCCESS_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from '../../../utils/constants';
import { ERROR, SUCCESS } from '../../../dto/constants.dto';
import PrimaryButton from '../../../components/common/PrimaryButton';
import ImageHolder from '../../../components/user/ImageHolder';
import LoadingOverlay from '../../../components/layout/LoadingOverlay';
import { UserAvailability } from '../../authentication/ts/auth_interfaces';
import AvailabilityService from '../../../services/availability.service';
import CandidatesService from '../../candidates/services/candidates.service';
import BackArrowLink from '../../../components/common/BackArrowLink';
import useUser from '../../authentication/hooks/useUser';


interface Props {
    className?: string;
}

function AccessForm({ className = '' }: Props) {
    const navigate = useNavigate();
    const {
        user, isUserLoading, error, userRole,
    } = useUser();
    const { accessId } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

    const [access, setAccess] = useState<UserAccessDto>();
    const [companies, setCompanies] = useState<UserAccessCompany[]>([]);
    const [roles, setRoles] = useState<UserAccessGroup[]>([]);

    const [availabilities, setAvailabilities] = useState<UserAvailability[]>([]);
    const [selectedAvail, setSelectedAvail] = useState<string>('');

    const selectedAvailClass = useMemo(() => CandidatesService.getCandidateAvailabilityClass(selectedAvail), [selectedAvail]);

    const fetchAccess = async () => {
        if (!accessId) {
            return null;
        }
        setIsLoading(true);
        const userAccess = await AccessService.getById(accessId);
        setIsLoading(false);
        if (!userAccess) {
            navigate('/');
            return null;
        }
        setCompanies(userAccess.companies);
        setRoles(userAccess.groups);

        userAccess.companies = [];
        userAccess.groups = [];
        setAccess(userAccess);
        return userAccess;
    };

    useEffect(() => {
        fetchAccess().then((userAccess) => {
            AvailabilityService.get().then((avails) => {
                setAvailabilities(avails);
                const strAvailability = userAccess?.availabilities.find((avail) => avail.checked);
                setSelectedAvail(strAvailability?.id ?? '');
            });
        });
    }, [accessId]);


    const companySelect = (company: UserAccessCompany, index: number) => {
        const newCompanies = [...companies];
        newCompanies[index].checked = !newCompanies[index].checked;
        setCompanies(newCompanies);
    };


    const roleSelect = (role: UserAccessGroup, index: number) => {
        const newRoles = [...roles];

        newRoles.forEach((item) => {
            item.checked = false;
        });
        newRoles[index].checked = !newRoles[index].checked;
        setRoles(newRoles);
    };

    const handleSuccess = (response: any): void => {
        notifications.success(GENERAL_SUCCESS_MESSAGE);
    };

    const groupsDisabled = () => {
        if (user !== null && user?.is_superuser) {
            return false;
        }
        return true;
    };

    const areGroupsDisabled = useMemo(() => groupsDisabled(), [user]);

    const handleFail = (response: any): void => {
        // void
    };

    const updateAction = async () => {
        if (!accessId) {
            return;
        }
        setIsSaveLoading(true);
        const strGroups = roles.filter((role) => role.checked).map((role) => role.id);
        const strCompanies = companies.filter((company) => company.checked).map((company) => company.id);
        const obj = {
            accessId,
            full_name: access?.full_name ?? '',
            availability: selectedAvail,
            groups: strGroups,
            companies: strCompanies,
        };
        const { response, status } = await AccessService.update(obj);
        setIsSaveLoading(false);

        if (status === ERROR) {
            handleFail(response);
        } else {
            handleSuccess(response);
            navigate('/access');
        }
    };

    const cancelAction = () => {
        navigate('/access');
    };
    const onDeleteAction = () => {
        if (isDeleteLoading) {
            return;
        }
        if (!access?.user_id) {
            Sentry.captureMessage(`No user id for this access: ${access?.id}`);
            return;
        }
        // eslint-disable-next-line no-restricted-globals,no-undef,no-alert
        if (!confirm('Are you sure you want to delete access? This action cannot be undone!')) {
            return;
        }

        setIsDeleteLoading(true);
        AccessService.deleteUser(access?.user_id).then((res) => {
            setIsDeleteLoading(false);
            const { response, status } = res;
            if (status === SUCCESS) {
                navigate('/access');
                notifications.success(GENERAL_SUCCESS_MESSAGE);
            }
            // notifications.error(UNEXPECTED_ERROR_MESSAGE);
        });
    };
    const saveButton = async () => {
        if (accessId) {
            await updateAction();
        } else {
            notifications.error(UNEXPECTED_ERROR_MESSAGE);
        }
    };

    const onClickEdit = () => {
        navigate(`/resume/${accessId}/edit`);
    };

    if (isLoading) {
        return (<LoadingOverlay />);
    }

    return (
        <Layout>
            <div className="line">
                <div>
                    <div className="container-lg pe-md-2 ps-md-2" />
                </div>
                <div
                    className="align-items-center align-items-sm-center content d-flex d-sm-flex flex-wrap justify-content-between justify-content-sm-between mobilePadding "
                >
                    <div className="align-items-center d-flex flex-row">
                        <BackArrowLink to="/access" />
                        <div className="align-items-center">
                            <ImageHolder
                                image={access?.image ?? null}
                                name={access?.full_name}
                                className="me-4 ms-2 ovalImg"
                                initialsClassName="me-4 ms-2 ovalImg"
                            />
                        </div>
                        <div>
                            <p className="mb-1 profileName">{access?.full_name}</p>
                            <p className="mb-0 profileNumber">{access?.user_id}</p>
                        </div>
                    </div>
                    <div
                        className="availability-header align-items-center col-12 col-lg-8 col-md-6 d-flex d-lg-flex gap-4 d-sm-flex flex-lg-wrap flex-wrap justify-content-between justify-content-lg-end justify-content-sm-between ps-0 pt-3 pt-md-0"
                    >
                        <div className="d-flex justify-content-center">
                            <select
                                placeholder="Availability"
                                name="gender"
                                className={`availableBtn availability-select ${selectedAvailClass}`}
                                onChange={(e) => setSelectedAvail(e.target.value)}
                                value={selectedAvail}
                            >
                                {availabilities.map((avail) => (
                                    <option value={avail.id} key={avail.id}>{avail.name}</option>
                                ))}
                            </select>
                        </div>
                        <div
                            className="d-flex d-sm-flex gap-4 flex-row fullWithContainer justify-content-between mt-sm-0"
                        >
                            <PrimaryButton
                                text="Save"
                                onClick={() => saveButton()}
                                className="saveBtn"
                                isLoading={isSaveLoading}
                            />
                            <PrimaryButton
                                text="Delete"
                                onClick={onDeleteAction}
                                className="cancelBtn removeBtn companyCancelBtn me-0"
                                disabled={isDeleteLoading}
                            />
                            <PrimaryButton
                                text="Cancel"
                                onClick={() => cancelAction()}
                                className="cancelBtn"
                            />
                        </div>
                    </div>
                </div>
                <div className="content d-flex flex-row mobilePadding roleContainer">
                    <span className="fw-bold me-3 mt-4 mt-sm-0">Role</span>
                    {access?.is_superuser ? (
                        <div className="radioBtn">
                            <label className="form-check-label ms-3 text-capitalize" htmlFor="group_superuser">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    disabled
                                    checked
                                    onChange={() => null}
                                    name="access_role"
                                    id="group_superuser"
                                />
                                Superuser
                            </label>
                        </div>
                    ) : (
                        <div className="d-flex flex-wrap mt-4 mt-sm-0">
                            {roles.map((group, index) => (
                                <div className="radioBtn" key={`group_${group.id}`}>
                                    <label className="form-check-label ms-3 text-capitalize" htmlFor={group.name}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            disabled={areGroupsDisabled}
                                            checked={group.checked}
                                            onChange={() => roleSelect(group, index)}
                                            name="access_role"
                                            id={group.name}
                                        />
                                        {group.name.replace(/_/g, ' ')}
                                    </label>
                                </div>

                            ))}
                        </div>
                    )}

                </div>

                {!access?.is_superuser && (
                    <div className="content mobilePadding">
                        <div>
                            <p className="fw-bold">Companies</p>
                        </div>
                        <hr className="horizontalLine" />
                        {companies?.map((company, index) => (
                            <div key={`company_${company.id}`}>
                                <div
                                    className="align-items-center checkBoxContainer d-flex form-check"
                                >
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        id={`company_${company.id}`}
                                        onChange={() => companySelect(company, index)}
                                        checked={company.checked}
                                    />
                                    <ImageHolder
                                        image={company.image}
                                        name={company.name}
                                        initialsClassName="initials-holder accessImgOvalInitials companyImgOval me-4 ms-4"
                                        className="companyImgOval me-4 ms-4"
                                    />
                                    <label htmlFor={`company_${company.id}`} className="checkboxLabel">
                                        {company.name}
                                    </label>
                                </div>
                                <hr className="horizontalLine" />
                            </div>
                        ))}
                    </div>
                )}

            </div>
        </Layout>
    );
}

export default AccessForm;
