/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
    PostRequestProps,
    sendDeleteRequest,
    sendGetRequest,
    sendPostRequest,
    sendPutRequest,
} from '../../../lib/axios/request';
import {
    FunctionItemDo,
    FunctionItemDto,
    FunctionList,
    FunctionSkill,
    InsertFunctionDto,
    UpdateFunctionDo,
} from '../ts/function.interface';
import { ReperaterInput } from '../../resume/components/form/ResumeInputRepeater';

const FunctionService = {

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-47ec2e3b-f41d-4ff4-8681-d57043e40bac | Link}
     */
    async insert(data: InsertFunctionDto): Promise<PostRequestProps> {
        return await sendPostRequest('/functions/', data);
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-af3759e0-d97c-4766-91e4-c17849f36120 | Link}
     */
    async update(data: UpdateFunctionDo): Promise<PostRequestProps> {
        return await sendPutRequest(`/functions/${data.id}/`, data);
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-a147d1cb-dc6a-4692-8cfc-519b77105697 | Link}
     */
    async getForUser(): Promise<FunctionItemDo[]> {
        const response = await sendGetRequest('/my-functions/') as unknown as FunctionList;
        if (!response) {
            return [];
        }
        response.forEach((part, index, theArray) => {
            // @ts-ignore
            part.function_skills = this.resolveRepeaterInputsFromBackend(part.function_skills);
            // @ts-ignore
            part.function_tools = this.resolveRepeaterInputsFromBackend(part.function_tools);
        });
        return response as unknown as FunctionItemDo[];
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-027f4ad7-9eeb-4957-9906-d8a47f34923c | Link}
     */
    async getFunctionById(function_id: string): Promise<FunctionItemDo | null> {
        const response = await sendGetRequest(`/functions/${function_id}/`) as unknown as FunctionItemDto;
        if (!response) {
            return null;
        }
        // @ts-ignore
        response.function_skills = this.resolveRepeaterInputsFromBackend(response.function_skills);
        // @ts-ignore
        response.function_tools = this.resolveRepeaterInputsFromBackend(response.function_tools);
        return response as unknown as FunctionItemDo;
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-62378214-617a-4924-bfa7-3b866ff295c4 | Link}
     */
    async destroy(functionId: string): Promise<PostRequestProps> {
        return await sendDeleteRequest(`/functions/${functionId}/`, null);
    },

    resolveRepeaterInputsFromBackend(val: FunctionSkill[]): ReperaterInput[] {
        if (typeof val === 'string') {
            return [];
        }
        const arrForRepeater: ReperaterInput[] = [];
        val.forEach((item, index, theArray) => {
            arrForRepeater.push({ value: item });
        });
        return arrForRepeater;
    },

    prepareRepeaterValuesForBackend(array: ReperaterInput[]): FunctionSkill[] {
        const arrForBackend: FunctionSkill[] = [];
        array.forEach((item, index, theArray) => {
            arrForBackend.push(item.value);
        });
        return arrForBackend;
    },


};

export default FunctionService;