import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../common/LoadingSpinner';


interface Props {
    title?: string;
    isLoadingOutside?: boolean;
    useCompanyLayout?: boolean;
    onInputChange: (value: string) => void;
}

function TableHeader({
    title = '', onInputChange, isLoadingOutside = false, useCompanyLayout = false,
}: Props) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);

        const delayDebounceFn = setTimeout(() => {
            onInputChange(searchValue);
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    if (useCompanyLayout) {
        return (
            <div className="greybg usersGrid">
                <div className="usersGridLabel">
                    <p className="mb-0 profileNumber">Name</p>
                </div>
                <div className="d-none d-sm-flex usersGridLabel">
                    <p className="mb-0 profileNumber">Manager</p>
                </div>
                <div className="d-none d-sm-flex usersGridLabel">
                    <p className="mb-0 profileNumber">Candidates</p>
                </div>
                <div className="align-items-end justify-content-end ps-3 ps-sm-0 usersGridLabel">
                    <div className="th-loading d-flex">
                        <LoadingSpinner isLoading={isLoading} className="company" />
                        {/* <LoadingSpinner isLoading={isLoading} className="company" /> */}
                        <input
                            className="usersSearched"
                            placeholder="Search..."
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className="d-flex d-sm-flex fullWithMobile justify-content-center  align-items-center justify-content-sm-end pe-sm-0 ps-3 ps-sm-0"
        >
            <div className="th-loading">
                <LoadingSpinner isLoading={isLoading} />
                <input
                    className="usersSearched"
                    placeholder="Search..."
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                />
            </div>

        </div>
    );
}

export default TableHeader;