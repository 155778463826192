/* eslint-disable no-undef */
// Hook
import { useEffect, useState } from 'react';

// https://www.freecodecamp.org/news/javascript-keycode-list-keypress-event-key-codes/#:~:text=A%20Full%20List%20of%20Key%20Event%20Values
function useKeyPress(targetKey: string): boolean {
    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    // If pressed key is our target key then set to true
    function downHandler(evt: { key: string; }): void {
        if (evt.key === targetKey) {
            setKeyPressed(true);
        }
    }

    // If released key is our target key then set to false
    const upHandler = (evt: { key: string; }): void => {
        if (evt.key === targetKey) {
            setKeyPressed(false);
        }
    };

    // Add event listeners
    useEffect(() => {
        window?.addEventListener('keydown', downHandler);
        window?.addEventListener('keyup', upHandler);

        // Remove event listeners on cleanup
        return () => {
            window?.removeEventListener('keydown', downHandler);
            window?.removeEventListener('keyup', upHandler);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
}

export default useKeyPress;