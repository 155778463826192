/**
 * @param {number} months - Number of months to go back
 * @description Get yyyy-mm-dd time in the past for number of months sent in
 */
const getDateAgoByMonths = (months: number): string => {
    const result = new Date();
    const currentMonth = result.getMonth();

    if (months === 0) {
        result.setHours(0, 0, 0, 0);
        const YYYY_MM_DD = result.toLocaleDateString('sv');
        return YYYY_MM_DD;
    }

    result.setMonth(result.getMonth() - months);
    // If still in same month, set date to last day of previous month
    if (result.getMonth() === currentMonth) {
        result.setDate(0);
    }
    result.setHours(0, 0, 0, 0);
    const YYYY_MM_DD = result.toLocaleDateString('sv');
    return YYYY_MM_DD;
};

/**
 * @description Gets the date object and travels X number of months in the back
 * */
const getDateObjectByMonthAgo = (months: number): Date => {
    const result = new Date();
    const currentMonth = result.getMonth();

    result.setMonth(result.getMonth() - months);
    // If still in same month, set date to last day of previous month
    if (result.getMonth() === currentMonth) {
        result.setDate(0);
    }
    result.setHours(0, 0, 0, 0);
    return result;
};

/**
 * @description Gets the date object and travels X number of months in the back
 * */
const getDateObjectByYearsAgo = (years: number): Date => {
    const result = new Date();
    result.setFullYear(result.getFullYear() - years);
    return result;
};
/**
 * @description Gets the date YYYY_MM_DD string and travels X number of years in the back
 * */
const getDateOStringYearsAgo = (years: number): string => getDateObjectByYearsAgo(years).toLocaleDateString('sv');

const getHumanReadableDate = (YYYY_MM_DD: string): string => {
    const date = new Date(YYYY_MM_DD).toLocaleDateString('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    // format returned is: September 13, 2022
    return date;
};

const formatDateToYYYYMMDD = (date: Date): string => date.toLocaleDateString('sv');

export {
    getDateAgoByMonths,
    getHumanReadableDate,
    formatDateToYYYYMMDD,
    getDateObjectByMonthAgo,
    getDateObjectByYearsAgo,
    getDateOStringYearsAgo,
};

export default {
    getDateAgoByMonths,
    formatDateToYYYYMMDD,
    getDateObjectByMonthAgo,
    getDateObjectByYearsAgo,
    getDateOStringYearsAgo,
    getHumanReadableDate,
};
