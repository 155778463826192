import { PostRequestProps, sendPostRequest } from '../../../lib/axios/request';
import { ChangePasswordProps } from '../ts/forgot-password.interface';

const ForgotPassword = {

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-c7a91077-183c-45ce-b4ae-570d74bdd30b | Link}
     */
    async sendMagicLink(email: string): Promise<PostRequestProps> {
        return await sendPostRequest('/password-reset/', { email });
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-0574f72a-77cd-4444-ac86-9e82c07a60cb | Link}
     */
    async changePassword(data: ChangePasswordProps) {
        return await sendPostRequest('/password-reset/confirm/', data);
    },

};

export default ForgotPassword;