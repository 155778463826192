import React from 'react';
import LogoutButton from '../../features/authentication/components/LogoutButton';
import SidebarLink from '../common/SidebarLink';
import {
    rolesThatCanAccessAccess,
    rolesThatCanAccessCandidates,
    rolesThatCanAccessCompanies,
    rolesThatCanAccessFunctions,
    rolesThatCanAccessHomepage,
} from '../../config/routes';
import useUser from '../../features/authentication/hooks/useUser';
import { CANDIDATE_ID } from '../../features/authentication/ts/auth_interfaces';

interface Props {
    isSidebarOpen?: boolean,
}

function Sidebar({ isSidebarOpen = false }: Props) {
    const { userRole } = useUser();
    return (
        <div className="sticky-nav">
            <nav className="mb-0 navbar navbar-expand-lg navbar-light pt-0">
                <div>

                    {/* add .show to show/hide */}
                    <div className={`collapse navbar-collapse ${isSidebarOpen ? 'show' : ''}`} id="navbarTogglerDemo03">
                        <div className="navigation">
                            <div className="row">
                                <div
                                    className="col-12 col-lg-12 col-md-6 col-sm-6 d-flex d-md-flex d-sm-flex flex-column flex-sm-column justify-content-sm-end justify-content-start pe-xl-1 ps-xl-1"
                                >
                                    <SidebarLink
                                        icon="trophy"
                                        url="/"
                                        text="My Resume"
                                        roles={rolesThatCanAccessHomepage}
                                    />
                                    {/* Should not be shown to candidates in sidear, should only be allowed access to view page

                                    */}
                                    {(userRole !== CANDIDATE_ID) && (
                                        <SidebarLink
                                            icon="person"
                                            url="/candidates"
                                            text="Candidates"
                                            roles={rolesThatCanAccessCandidates}
                                        />
                                    )}

                                    {(userRole === CANDIDATE_ID) && (
                                        <SidebarLink
                                            icon="person"
                                            url="/functions"
                                            text="Functions"
                                            roles={rolesThatCanAccessFunctions}
                                        />
                                    )}
                                    <SidebarLink
                                        url="/companies"
                                        icon="palette"
                                        text="Companies"
                                        roles={rolesThatCanAccessCompanies}
                                    />
                                </div>
                                <div className="col-12 col-lg-12 col-md-6 col-sm-6  pe-xl-1 ps-xl-1">
                                    <SidebarLink
                                        icon="hierarchy"
                                        url="/access"
                                        text="Access"
                                        roles={rolesThatCanAccessAccess}
                                    />
                                    <LogoutButton />
                                </div>
                            </div>
                            <div className="mt-4 text-center" />
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Sidebar;