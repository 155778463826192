import { sendGetRequest } from '../lib/axios/request';
import { UserAvailability } from '../features/authentication/ts/auth_interfaces';

const AvailabilityService = {
    
    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/example/16173878-907c2fec-644a-46f9-89ce-3eb7e67d0c89 | Link}
     */
    async get(): Promise<UserAvailability[]> {
        return await sendGetRequest('/availabilities/');
    },
};

export default AvailabilityService;
