import React from 'react';
import { Link } from 'react-router-dom';

function ResumeAddFunction() {
    return (

        <div className="col-12 col-lg-4 col-md-4 col-sm-6 col-xl-auto d-flex justify-content-center p-3">
            <Link to="/function/create" className="addNewFunctioLink">
                <div
                    className="addNewFunctionBox d-flex flex-column justify-content-end p-4"
                >
                    <div className="mt-4 text-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            className="plusICon"
                        >
                            <path
                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                            />
                        </svg>
                        <div className="text-center" style={{ marginTop: '60px' }}>
                            <p className="defaultBLue">
                                <span>
                                    Add
                                </span>
                                <span>Function</span>
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>


    );
}

export default ResumeAddFunction;