import { sendGetRequest, sendPostRequest } from '../../../lib/axios/request';
import {
    Candidate,
    CandidatesList,
    CandidatesNumbers,
    CreateCandidateProps,
    GetCandidatesProps,
} from '../ts/candidates.interface';
import { AVAILABILITY_ARCHIEVED_ID, AVAILABILITY_AVAILABLE_ID, AVAILABILITY_BUSY_ID } from '../../../dto/constants.dto';

const CandidatesService = {

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-716a47e0-8a95-4e64-a50b-4352df135532 | Link}
     */
    async getNumbers(): Promise<CandidatesNumbers> {
        return await sendGetRequest('/candidates/numbers/');
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-f54559ea-ad7f-46c2-a901-b5e379973f6a | Link}
     */
    async getCandidates({
        search = '',
        nextPageUrl = null,
        previousPageUrl = null,
    }: GetCandidatesProps): Promise<CandidatesList> {
        if (previousPageUrl !== null) {
            return await sendGetRequest(previousPageUrl);
        }
        if (nextPageUrl !== null) {
            return await sendGetRequest(nextPageUrl);
        }
        if (search) {
            return await sendGetRequest(`/candidates/?search=${search}`);
        }
        return await sendGetRequest('/candidates/');
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-e7fbe330-3627-488d-a020-2f7b0b82ab63 | Link}
     */
    async getCandidateById(id: string): Promise<Candidate> {
        return await sendGetRequest(`/candidates/${id}/`);
    },

    async create(data: CreateCandidateProps) {
        return await sendPostRequest('/users/', data);
    },

    getCandidateAvailabilityClass(id: string): string {
        switch (id) {
        case (AVAILABILITY_BUSY_ID): {
            return 'busyStatus';
        }
        case (AVAILABILITY_AVAILABLE_ID): {
            return 'available';
        }
        case (AVAILABILITY_ARCHIEVED_ID): {
            return 'archived';
        }
        default: {
            return 'available';
        }
        }
    },
};

export default CandidatesService;