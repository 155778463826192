import React from 'react';
import PrimaryButton from '../../../components/common/PrimaryButton';


interface Props {
    title: string,
    subtitle: string,
    onEditClick: () => void,
}

function FunctionBox({ title, subtitle, onEditClick }: Props) {
    return (
        <div
            className="col-12 col-lg-4 col-md-4 col-sm-6 col-xl-auto d-flex justify-content-center p-3 ps-lg-2"
        >
            <div
                className="d-flex flex-column justify-content-md-center justify-content-lg-between professionDiv"
            >
                <div className="mb-5">
                    <p className="bold darkGrey text-start line-clamp-2">
                        {title}
                    </p>
                    <p className="defaultGreyText line-clamp-1">{subtitle}</p>
                </div>
                <div className="d-flex justify-content-center">
                    <PrimaryButton
                        className="editBtn"
                        onClick={onEditClick}
                        text="Edit"
                    />
                </div>
            </div>
        </div>
    );
}

export default FunctionBox;