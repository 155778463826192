import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import TimeHelper from '../../utils/TimeHelper';

export interface DatePickerRangeSelectAction {
    start_YYYY_MM_DD: string,
    end_YYYY_MM_DD: string,
    startDate: Date,
    endDate: Date,
}

interface Props {
    startDateObj: Date | null;
    errorText?: string;
    className?: string;
    allowFutureDates?: boolean;
    label?: string;
    onChange: (obj: DatePickerRangeSelectAction) => void;
}

function DatePickerRange({
    startDateObj = null,
    className = '',
    errorText = '',
    label = 'Date',
    onChange,
    allowFutureDates = false,
}: Props) {
    const [startDate, setStartDate] = useState<Date>(startDateObj ?? new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    const prepareObject = (start: Date, end: Date): DatePickerRangeSelectAction => ({
        startDate: start,
        endDate: end,
        start_YYYY_MM_DD: TimeHelper.formatDateToYYYYMMDD(start),
        end_YYYY_MM_DD: TimeHelper.formatDateToYYYYMMDD(end),
    });

    const onChangeStartRange = (date: Date | null) => {
        if (!date) {
            console.warn('No start date range');
            return;
        }
        setStartDate(date);
        if (endDate) {
            onChange(prepareObject(date, endDate));
        }
    };

    const onChangeEndRange = (date: Date | null) => {
        if (!date) {
            console.warn('No end date range');
            return;
        }
        setEndDate(date);
        onChange(prepareObject(startDate, date));
    };

    useEffect(() => {
        onChange(prepareObject(startDate, endDate));
    }, []);

    return (
        <div className="d-flex flex-column">
            <label className="editLabel mb-2">Time Period</label>
            <div className="d-flex">
                <DatePicker
                    maxDate={(allowFutureDates ? null : addDays(new Date(), 0))}
                    selected={startDate}
                    onChange={onChangeStartRange}
                    selectsStart
                    locale="en"
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="dd/MM/yyyy"
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                />
                {errorText && (
                    <span className="error-text">{errorText}</span>
                )}
                <div className="pg-empty-placeholder" style={{ width: '20px' }} />
                <DatePicker
                    maxDate={(allowFutureDates ? null : addDays(new Date(), 0))}
                    yearDropdownItemNumber={50}
                    selected={endDate}
                    onChange={onChangeEndRange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    locale="en"
                    placeholderText="dd/MM/yyyy"
                    dateFormat="dd/MM/yyyy"
                    todayButton="Today"
                    showYearDropdown
                    scrollableYearDropdown
                />
                {errorText && (
                    <span className="error-text">{errorText}</span>
                )}
            </div>
        </div>
    );
}

export default DatePickerRange;
