/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { makeAutoObservable, runInAction } from 'mobx';
import * as Sentry from '@sentry/react';
import { AuthService } from '../services/auth.service';
import { errorLog } from '../services/errorhandler.service';
import { CurrentUser, LoginRequest, LoginResponse } from '../features/authentication/ts/auth_interfaces';
import UserService, { userFieldMutator } from '../features/authentication/services/UserService';

export class AuthStore {
    private authenticated = false;

    private user: CurrentUser | null = null;

    constructor(private readonly authService: AuthService) {
        makeAutoObservable(this);
        this.authenticated = !!this.getAccessToken();
    }

    getAccessToken() {
        return localStorage.getItem('access_token');
    }

    removeAccessToken() {
        return localStorage.removeItem('access_token');
    }

    // correct way with async await (the cleanest way in my opinion)
    getUser = async (): Promise<CurrentUser | null> => {
        if (this.user?.id) {
            return this.user;
        }
        let data: CurrentUser | null = await UserService.getCurrentUser();
        data = userFieldMutator(data);
        runInAction(() => {
            this.user = data;
        });
        return data;
    };

    isAuthenticated() {
        return this.authenticated;
    }

    async logout(): Promise<any> {
        try {
            const response = await this.authService.logout();
            this.removeAccessToken();
            this.setAuthenticated(false);
            return response;
        } catch (err) {
            errorLog(err);
            this.setAuthenticated(false);
            this.removeAccessToken();
            return err;
        }
    }

    async login(loginRequest: LoginRequest): Promise<LoginResponse> {
        try {
            const loginResponse = await this.authService.login(loginRequest);
            if (!loginResponse.token) {
                console.warn('%cNo token in the login response', 'color:orange;');
                return loginResponse;
            }
            localStorage.setItem('access_token', loginResponse.token);
            this.setAuthenticated(true);
            return loginResponse;
        } catch (err) {
            Sentry.captureException(err, {
                tags: {
                    section: 'login',
                },
            });
            await this.logout();
            return err as LoginResponse;
        }
    }

    private setAuthenticated(authenticated: boolean) {
        this.authenticated = authenticated;
    }
}