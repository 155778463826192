import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import ResumeInputRepeater, { ReperaterInput } from '../../resume/components/form/ResumeInputRepeater';
import FunctionService from '../services/function.service';
import { ERROR } from '../../../dto/constants.dto';
import useUser from '../../authentication/hooks/useUser';
import notifications from '../../../utils/notifications';
import { GENERAL_ERROR_MESSAGE, GENERAL_SUCCESS_MESSAGE } from '../../../utils/constants';
import PrimaryButton from '../../../components/common/PrimaryButton';
import { usePrompt } from '../../../hooks/usePrompt';
import LoadingOverlay from '../../../components/layout/LoadingOverlay';
import useAutoFocus from '../../../hooks/useAutoFocus';
import BackArrowLink from '../../../components/common/BackArrowLink';
import { CANDIDATE_ID } from '../../authentication/ts/auth_interfaces';


interface Props {
    className?: string;
}

function CreateFunction({ className = '' }: Props) {
    const {
        user, userRole,
    } = useUser();
    const navigate = useNavigate();

    const { inputRef: nameInput } = useAutoFocus();
    const { functionId } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
    const [isDestroyLoading, setIsDestroyLoading] = useState<boolean>(false);
    // Inputs Start
    const [functionName, setFunctionName] = useState<string | null>(null);
    const [summary, setSummary] = useState<string>();
    const [functionSkills, setFunctionSkills] = useState<ReperaterInput[]>([]);
    const [functionTools, setFunctionTools] = useState<ReperaterInput[]>([]);
    const [formErrors, setFormErrors] = useState({
        name: '', summary: '', function_skills: '', function_tools: '',
    });


    const { triggerPrompt } = usePrompt();


    const toolsInputOnChange = (tools: ReperaterInput[]) => {
        setFunctionTools(tools);
    };

    const skillInputOnChange = (skills: ReperaterInput[]) => {
        setFunctionSkills(skills);
    };


    const handleSuccess = (response: any) => {
        if (!user || !userRole) {
            return;
        }
        setFormErrors({
            name: '', summary: '', function_skills: '', function_tools: '',
        });
        notifications.success(GENERAL_SUCCESS_MESSAGE);
        if (userRole === CANDIDATE_ID) {
            navigate('/');
        } else {
            navigate(`/candidates/${functionId}`);
        }
    };

    const handleFail = (response: any) => {
        if (response.data.name) {
            setFormErrors({ ...formErrors, name: response.data.name });
        }
        if (response.data.summary) {
            setFormErrors({ ...formErrors, summary: response.data.summary[0] });
        }
        if (response.data.function_skills) {
            setFormErrors({ ...formErrors, function_skills: response.data.function_skills[0] });
        }
        if (response.data.function_tools) {
            setFormErrors({ ...formErrors, function_tools: response.data.function_tools[0] });
        }
    };

    const updateAction = async (): Promise<void> => {
        if (!user) {
            notifications.error('No user found, please try again.');
            return;
        }
        if (!functionId) {
            notifications.error('No function ID found. Please try again.');
            return;
        }

        const { response, status } = await FunctionService.update({
            id: functionId,
            name: functionName ?? '',
            summary: summary ?? '',
            function_skills: FunctionService.prepareRepeaterValuesForBackend(functionSkills),
            function_tools: FunctionService.prepareRepeaterValuesForBackend(functionTools),
            user_id: user.user,
        });
        if (status === ERROR) {
            handleFail(response);
        } else {
            handleSuccess(response);
        }
    };

    const destroyAction = async () => {
        if (isDestroyLoading) {
            return;
        }
        if (!user) {
            notifications.error('No user found, please try again.');
            return;
        }
        if (!functionId) {
            notifications.error('No function ID found. Please try again.');
            return;
        }
        setIsDestroyLoading(true);
        const { response, status } = await FunctionService.destroy(functionId);
        setIsDestroyLoading(false);
        if (status === ERROR) {
            notifications.error(GENERAL_ERROR_MESSAGE);
        } else {
            handleSuccess(response);
        }
    };

    const saveAction = async () => {
        if (!user) {
            notifications.error('No user found, please try again.');
            return;
        }
        if (functionId) {
            await updateAction();
            return;
        }
        setIsSaveLoading(true);
        const { response, status } = await FunctionService.insert({
            name: functionName ?? '',
            summary: summary ?? '',
            function_skills: FunctionService.prepareRepeaterValuesForBackend(functionSkills),
            function_tools: FunctionService.prepareRepeaterValuesForBackend(functionTools),
        });
        setIsSaveLoading(false);
        if (status === ERROR) {
            handleFail(response);
        } else {
            handleSuccess(response);
        }
    };


    // @ts-ignore
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if ((e.code === 'Enter' || e.code === 'NumpadEnter') && !isSaveLoading) {
            saveAction();
        }
    };


    const deletePrompt = () => {
        triggerPrompt({
            title: 'Warning',
            text: 'Are you sure you want to delete this function?',
            onSubmit: destroyAction,
        });
    };

    useEffect(() => {
        if (!functionId) {
            return;
        }
        setIsLoading(true);
        FunctionService.getFunctionById(functionId).then((response) => {
            if (!response) {
                navigate('/');
                return;
            }
            setIsLoading(false);
            setFunctionName(response.name);
            setSummary(response.summary);
            setFunctionSkills(response.function_skills);
            setFunctionTools(response.function_tools);
        });
    }, [functionId]);

    if (functionId && isLoading) {
        return <LoadingOverlay />;
    }

    return (
        <Layout>
            <div className="content line mobilePadding">
                <div className="align-items-center d-flex justify-content-between">
                    <BackArrowLink to="/" />
                    <div className="d-flex">
                        {functionId && (
                            <PrimaryButton
                                text="Delete"
                                onClick={() => deletePrompt()}
                                className="cancelBtn removeBtn function"
                                isLoading={isDestroyLoading}
                            />
                        )}
                        <PrimaryButton
                            text="Save"
                            onClick={() => saveAction()}
                            className="saveBtn"
                            isLoading={isSaveLoading}
                        />
                    </div>
                </div>
                <div className="me-auto ms-auto mt-4 mt-md-5 mt-sm-3 function-wrapper">
                    <p className="defaultGreyText">Function</p>
                    <div className="align-items-center d-flex fn-name-input">
                        <input
                            ref={nameInput}
                            className="editInput "
                            onKeyDown={handleKeyDown}
                            onChange={(e) => setFunctionName(e.target.value)}
                            value={functionName ?? ''}
                            placeholder="Java developer"
                        />
                    </div>
                    {formErrors?.name && (
                        <small className="error-text">{formErrors?.name}</small>
                    )}
                    <div className="mt-5">
                        <p className="defaultGreyText">Summary</p>
                    </div>
                    <textarea
                        className="editTextArea function mt-3"
                        defaultValue={summary}
                        onChange={(e) => setSummary(e.target.value)}
                    />
                    {formErrors?.summary && (
                        <small className="error-text">{formErrors?.summary}</small>
                    )}
                    <div className="mb-5 mt-5 mt-md-4">
                        <ResumeInputRepeater
                            identifier="skills"
                            valuesArr={functionSkills}
                            label="Function specific skills"
                            onChangeAction={(e) => skillInputOnChange(e)}
                            errorText={formErrors?.function_skills}
                        />
                    </div>
                    <div className="mt-md-4">
                        <ResumeInputRepeater
                            identifier="tools"
                            valuesArr={functionTools}
                            label="Function specific tools"
                            errorText={formErrors?.function_tools}
                            onChangeAction={(e) => toolsInputOnChange(e)}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CreateFunction;