interface AlertDo {
    read: true;
}

const objectForLocalStorage = ({ read }: AlertDo): string => JSON.stringify({ read });
const resolveLocalStorageObj = (object: string): AlertDo => JSON.parse(object);

/* eslint-disable no-undef */
const markAsRead = (identifier: string): void => {
    localStorage.setItem(identifier, objectForLocalStorage({ read: true }));
};

const hasBeenRead = (identifier: string): boolean => {
    const lsItem = localStorage.getItem(identifier);
    if (!lsItem) {
        return false;
    }
    const content = resolveLocalStorageObj(lsItem);
    return content.read;
};


export {
    markAsRead,
    hasBeenRead,
};

export default {
    markAsRead,
    hasBeenRead,
};