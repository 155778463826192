import React, { createContext, useState } from 'react';

export type PromptProps = {
    title: string;
    text: string;
    onSubmit: () => void;
};

type PromptContextValue = {
    isOpen: boolean;
    props: PromptProps;
};

type SetPromptContextValue = React.Dispatch<React.SetStateAction<PromptContextValue>>;

const defaultValue: PromptContextValue = {
    isOpen: false,
    props: {
        title: '',
        text: '',
        onSubmit: () => null,
    },
};

export const PromptContext = createContext<[PromptContextValue, SetPromptContextValue]>([defaultValue, () => {
}]);

interface ContextProps {
    children: React.ReactNode,
}

export function PromptContextProvider({ children }: ContextProps) {
    const [prompt, setPrompt] = useState<PromptContextValue>(defaultValue);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <PromptContext.Provider value={[prompt, setPrompt]}>
            {children}
        </PromptContext.Provider>
    );
}