import React, { ChangeEvent, KeyboardEvent } from 'react';
import useAutoFocus from '../../../../hooks/useAutoFocus';

interface Props {
    label?: string | null,
    value: string,
    name?: string | undefined,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void,
    placeholder: string
    errorText?: string
    required?: boolean
    autoFocus?: boolean
    className?: string
}

function ResumeInput({
    label = null,
    onChange,
    name = undefined,
    placeholder,
    value,
    className = '',
    autoFocus = false,
    errorText = '',
    onKeyDown = () => null,
    required = false,
}: Props) {
    const { inputRef: nameAutoFocus } = useAutoFocus();
    return (
        <div className={`d-flex flex-column formGridItem ${className}`}>
            {label !== null && (
                <label className={`editLabel ${required ? 'required' : ''}  mb-2`}>{label}</label>
            )}
            <input
                ref={(autoFocus ? nameAutoFocus : undefined)}
                className="editInput"
                value={value}
                onKeyDown={onKeyDown}
                name={name}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
            />
            {errorText && (
                <small className="error-text">{errorText}</small>
            )}
        </div>
    );
}

export default ResumeInput;