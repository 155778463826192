import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from '../../authentication/hooks/useUser';
import { SALES_MANAGER_ID } from '../../authentication/ts/auth_interfaces';
import { Candidate, CandidateTemplate } from '../ts/candidates.interface';
import AccessService from '../../access/services/access.service';
import PrimaryButton from '../../../components/common/PrimaryButton';


interface Props {
    magicLinkAction: (templateId: string) => void;
    pdfBtnAction: (templateId: string) => void;
    candidate: Candidate,
    isDocLoading: boolean,
    isPdfLoading: boolean,
    isMagicLinkLoading: boolean,
    template: CandidateTemplate,
    docBtnAction: (templateId: string) => void;
}

function CandidateTemplateBox({
    magicLinkAction,
    pdfBtnAction,
    docBtnAction,
    candidate,
    template,
    isMagicLinkLoading,
    isPdfLoading,
    isDocLoading,
}: Props) {
    const { user, userRole, isUserLoading } = useUser();
    const [hidePrivateInfo, setHidePrivateInfo] = useState<boolean>(candidate.usermeta.show_privacy);

    const onEyeClick = () => {
        const newState = !hidePrivateInfo;
        setHidePrivateInfo(newState);
        AccessService.updateUserPrivacy(newState, candidate.usermeta.id).then((r) => null);
    };
    const navigate = useNavigate();
    const onFunctionEditClick = () => {
        navigate(`/function/${candidate.id}/edit`);
    };

    if (isUserLoading || !user) {
        return null;
    }

    return (
        <div className="mt-4 p-3 templateBox">
            <div
                className="align-items-center d-flex d-sm-flex flex-row fullWithMobile justify-content-sm-center justify-content-start"
            >
                <div className="align-items-center d-flex justify-content-center temlateIconBox cursor-pointer">
                    {userRole === SALES_MANAGER_ID ? (
                        <i className="eyeIcon fa-eye far" />
                    ) : (
                        <button
                            type="button"
                            onClick={() => onEyeClick()}
                            className="candidate-eye"
                            title="Hide private information in the exported document."
                        >
                            <i className={`eyeIcon ${hidePrivateInfo ? 'fa-eye' : 'fa-eye-slash'}  far`} />
                        </button>
                    )}
                </div>
                <span className="ms-3 ms-sm-4 mt-0 mt-sm-0">{template.name}</span>
            </div>
            <div
                className="align-items-center d-flex d-sm-flex flex-row flex-sm-row flex-wrap justify-content-start gap-2"
            >
                <PrimaryButton
                    className="editBtn"
                    onClick={() => onFunctionEditClick()}
                    text="Edit CV"
                />
                <PrimaryButton
                    isLoading={isPdfLoading}
                    onClick={() => pdfBtnAction(template.id)}
                    className="pdfBtn"
                    text="PDF"
                />
                <PrimaryButton
                    isLoading={isDocLoading}
                    onClick={() => docBtnAction(template.id)}
                    className="docBtn ms-0"
                    text=".DOC"
                />
                <PrimaryButton
                    isLoading={isMagicLinkLoading}
                    onClick={() => magicLinkAction(template.id)}
                    className="defaultBLue magic-link"
                    text={`${isMagicLinkLoading ? '' : 'Magic link'}`}
                />
            </div>
        </div>
    );
}

export default CandidateTemplateBox;
