import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


interface Props {
    children: React.ReactNode;
}

function LoginLayout({ children }: Props) {
    const [customLogoSmall, setCustomLogoSmall] = useState<string | null>(null);
    const [customLogoLarge, setCustomLogoLarge] = useState<string | null>(null);
    useEffect(() => {
        const tmpCustomLogoSmall = process.env.REACT_APP_LOGO_URL_SMALL;
        const tmpCustomLogoLarge = process.env.REACT_APP_LOGO_URL_FULL;
        // const tmpCustomLogoLarge = '/assets/MorganCode-full.png';
        // const tmpCustomLogoSmall = '/assets/MorganCode-small.png';
        if (tmpCustomLogoSmall) {
            setCustomLogoSmall(tmpCustomLogoSmall);
        }
        if (tmpCustomLogoLarge) {
            setCustomLogoLarge(tmpCustomLogoLarge);
        }
    }, []);

    return (
        <div>
            <div className="headerbg">
                <div className="align-items-center d-flex">
                    <Link to="/">
                        {(customLogoSmall && customLogoLarge) ? (
                            <>
                                <img
                                    alt="Logo"
                                    src={customLogoLarge}
                                    style={{ filter: 'invert(1)', height: '2.7rem' }}
                                    className="blogo d-sm-none d-none d-md-block"
                                />
                                <img
                                    alt="Logo"
                                    src={customLogoSmall}
                                    style={{ filter: 'invert(1)', height: '2.7rem' }}
                                    className="blogo d-md-none"
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    alt="Logo"
                                    src="/assets/Espelavo.svg"
                                    className="blogo d-sm-none d-none d-md-block"
                                />
                                <img
                                    alt="Logo"
                                    src="/assets/logo.svg"
                                    className="blogo d-md-none"
                                />
                            </>
                        )}
                    </Link>
                    {/* <img src="/assets/logo_simple.svg" alt="CV MAKER" className="blogo" /> */}
                </div>
            </div>
            <main className="align-items-center d-flex justify-content-center loginMain">
                {children}
            </main>
        </div>
    );
}

export default LoginLayout;