import React from 'react';


function CandidateNoResults() {
    return (
        <h4 className="mt-3">No results found</h4>
    );
    // return (
    //     <div className="col-md-auto col-sm-6 pb-2 pb-sm-2 pe-sm-2 pt-2 pt-sm-2">
    //         <div className="candidatesCard p-2">
    //             <div className="align-items-center d-flex justify-content-between">
    //                 <span className="candidateNUmber">0</span>
    //                 <div className="candidate-status busyStatus" />
    //             </div>
    //             <div className="d-flex justify-content-center ">
    //                 <ImageHolder
    //                     className="ovalImg"
    //                     image={null}
    //                     name="X"
    //                 />
    //             </div>
    //             <div className="align-items-center d-flex flex-column justify-content-center">
    //                 <span className="candidatesName mt-3 text-center">No results found</span>
    //             </div>
    //         </div>
    //     </div>
    // );
}

export default CandidateNoResults;