/* eslint-disable no-undef */

import React, { useContext, useEffect, useRef } from 'react';
import PrimaryButton from './PrimaryButton';
import { PromptContext } from '../../context/PromptContext';
import useKeyPress from '../../hooks/useKeyPress';
import useOnClickOutside from '../../hooks/useOnClickOutside';


export function PromptModal() {
    const [prompt, setPrompt] = useContext(PromptContext);

    const isEscapeClicked = useKeyPress('Escape');

    const {
        isOpen,
        props: {
            text, onSubmit,
        },
    } = prompt;

    const closePrompt = () => setPrompt({ ...prompt, isOpen: false });

    useEffect(() => {
        if (isEscapeClicked && isOpen) {
            closePrompt();
        }
    }, [isEscapeClicked]);

    const modalRef = useRef(null);
    const isClickedOutside = useOnClickOutside(modalRef);

    const closeModal = () => {
        closePrompt();
    };

    useEffect(() => {
        if (isClickedOutside && isOpen) {
            closeModal();
        }
    }, [isClickedOutside]);


    const onSubmitAction = () => {
        closePrompt();
        onSubmit();
    };

    if (!isOpen) {
        return null;
    }
    return (
        <div className="customModalContainer">
            <div className="cv-modal p-4" ref={modalRef}>
                <div className="closeIcon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        className="xmark cursor-pointer"
                        onClick={() => closePrompt()}
                    >
                        <path
                            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                        />
                    </svg>
                </div>
                <div className="d-flex">
                    <div className="d-flex flex-column">
                        <p
                            className="mb-4 pe-3 ps-4 text-start"
                            style={{
                                fontSize: '18px',
                                lineHeight: '20px',
                            }}
                        >
                            {text}
                        </p>
                        <div className="d-flex justify-content-center">
                            <PrimaryButton text="Proceed" onClick={() => onSubmitAction()} className="removeBtn" />
                            <PrimaryButton text="Cancel" onClick={() => closePrompt()} className="cancelBtn ms-3" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PromptModal;