/* eslint-disable no-undef */
import React, { ChangeEvent, useEffect, useState } from 'react';
import PrimaryButton from '../../../../components/common/PrimaryButton';
import { ResumeFormErrors, UserEducation } from '../../ts/resume.interface';
import ResumeInput from '../form/ResumeInput';
import ResumeTextArea from '../form/ResumeTextArea';
import DatePickerRange, { DatePickerRangeSelectAction } from '../../../../components/common/DatePickerRange';


interface Props {
    className?: string;
    userEducations: UserEducation[];
    formErrors: ResumeFormErrors;
    onChangeEducation: (educations: UserEducation[]) => void;
}

function EducationRepeater({
    className = '', onChangeEducation, userEducations, formErrors,
}: Props) {
    const [educations, setEducations] = useState<UserEducation[]>([]);

    /* If user has any existing user education, use that one, if not, create new instance of education */
    useEffect(() => {
        if (userEducations.length > 0) {
            setEducations(userEducations);
        }
    }, []);

    /* Update parent element only when users stop typing */
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onChangeEducation(educations);
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [educations]);

    const scrollIntoView = (lastIndex: number) => {
        const el = document.getElementById(`education_index_${lastIndex}`);
        if (!el) {
            return;
        }
        el.scrollIntoView();
    };

    const addNew = () => {
        const newArr = [...educations];
        newArr.push({
            institution_name: '',
            field_of_study: '',
            country: '',
            city_education: '',
            period_from: '',
            period_to: '',
            description: '',
        });
        setEducations(newArr);
        onChangeEducation(newArr);
        setTimeout(() => {
            scrollIntoView((newArr.length - 1));
        }, 200);
    };

    const onChangeEvt = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const newArr = [...educations];
        // @ts-ignore
        newArr[index][e.target.name] = e.target.value;
        setEducations(newArr);
    };

    const onEducationRangeChange = (eduRange: DatePickerRangeSelectAction, index: number) => {
        const newArr = [...educations];
        // @ts-ignore
        newArr[index].period_from = eduRange.start_YYYY_MM_DD;
        newArr[index].period_to = eduRange.end_YYYY_MM_DD;
        setEducations(newArr);
    };
    const removeEducation = (index: number) => {
        const arr = [...educations];
        arr.splice(index, 1);
        setEducations(arr);
    };

    return (
        <div>
            <div className="align-items-center d-flex justify-content-between mb-3">
                <span className="defaultGreyText">Educations</span>
            </div>
            <hr className="line mb-3" style={{ height: 'auto' }} />

            {educations.map((education, index) => (
                <div
                    id={`education_index_${index}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`education_repeater_${index}`}
                    style={{ scrollMargin: '18px' }}
                >
                    <div className="align-items-center d-flex justify-content-end mb-3">
                        <PrimaryButton
                            text="Remove education"
                            onClick={() => removeEducation(index)}
                            className="removeEducatioBtn"
                        />
                    </div>
                    <div>
                        <p className="defaultGreyText">
                            Education no.
                            {' '}
                            {index + 1}
                        </p>
                    </div>
                    <div className="formGrid">
                        <ResumeInput
                            label="Institution Name"
                            name="institution_name"
                            className="formGridItem"
                            value={educations[index].institution_name}
                            onChange={(e) => onChangeEvt(e, index)}
                            placeholder="TU, Eindhoven"
                            errorText={formErrors.institution_name[index] ? formErrors.institution_name[index] : ''}
                        />
                        <ResumeInput
                            label="Field of Study"
                            name="field_of_study"
                            className="formGridItem"
                            value={educations[index].field_of_study}
                            onChange={(e) => onChangeEvt(e, index)}
                            placeholder="e.g Business"
                            errorText={formErrors.field_of_study[index] ? formErrors.field_of_study[index] : ''}
                        />
                        <ResumeInput
                            className="formGridItem"
                            label="Country"
                            name="country"
                            value={educations[index].country}
                            onChange={(e) => onChangeEvt(e, index)}
                            placeholder="The Netherlands"
                            errorText={formErrors.country[index] ? formErrors.country[index] : ''}
                        />
                        <ResumeInput
                            className="formGridItem"
                            label="City"
                            name="city_education"
                            value={educations[index].city_education}
                            onChange={(e) => onChangeEvt(e, index)}
                            placeholder="City"
                            errorText={formErrors.city_education[index] ? formErrors.city_education[index] : ''}
                        />

                        <DatePickerRange
                            startDateObj={null}
                            onChange={(eduRange) => onEducationRangeChange(eduRange, index)}
                        />
                    </div>

                    <ResumeTextArea
                        className="formGridItem mt-4"
                        label="Description"
                        name="description"
                        value={educations[index].description}
                        onChange={(e) => onChangeEvt(e as unknown as ChangeEvent<HTMLInputElement>, index)}
                        placeholder="Short description of your education."
                    />
                    <hr className="line mt-5" style={{ height: 'auto' }} />
                </div>
            ))}
            <div className="align-items-center d-flex justify-content-end f">
                <PrimaryButton
                    text="Add education"
                    onClick={() => addNew()}
                    className="addCandidateBtn"
                />
            </div>
        </div>

    );
}

export default EducationRepeater;