import React, { ChangeEvent, KeyboardEvent } from 'react';

interface Props {
    label: string,
    value: string,
    name?: string | undefined,
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void,
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void,
    placeholder: string
    errorText?: string
    className?: string
}

function ResumeTextArea({
    label,
    onChange,
    name = undefined,
    placeholder,
    value,
    className = '',
    errorText = '',
    onKeyDown = () => null,
}: Props) {
    return (
        <div className={`d-flex flex-column ${className}`}>
            <label className="editLabel mb-2">{label}</label>
            <textarea
                className="editTextArea"
                value={value}
                onKeyDown={onKeyDown}
                name={name}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
            />
            {errorText && (
                <small className="error-text">{errorText}</small>
            )}
        </div>
    );
}

export default ResumeTextArea;