import React from 'react';

function LoadingOverlay() {
    return (
        <div className="d-flex align-items-center justify-content-center logoOverlay">
            <img alt="logoImage" src="/assets/logo.svg" className="logoAnimation" />
        </div>
    );
}

export default LoadingOverlay;