/* eslint-disable react/button-has-type */
import React, { CSSProperties } from 'react';
import LoadingSpinner from './LoadingSpinner';

interface Props {
    text: string;
    className?: string;
    style?: CSSProperties | undefined;
    type?: 'reset' | 'submit' | 'button';
    onClick: () => void;
    disabled?: boolean | false;
    isLoading?: boolean | false;
}

function PrimaryButton({
    text,
    disabled = false,
    isLoading = false,
    onClick,
    type,
    className = '',
    style,
}: Props) {
    const isDisabled = (): boolean => {
        if (disabled) {
            return true;
        }
        if (isLoading) {
            return true;
        }
        return false;
    };

    return (
        <button
            type={(type ?? 'button')}
            onClick={onClick}
            className={`primary-btn ${className} ${isLoading ? 'loading-active' : ''}`}
            style={style ?? undefined}
            disabled={isDisabled()}
        >
            {isLoading && (
                <LoadingSpinner isLoading />
            )}
            {text}
        </button>
    );
}

export default PrimaryButton;