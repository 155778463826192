import { UserEducation } from '../../resume/ts/resume.interface';
import { FunctionSkill } from '../../functions/ts/function.interface';

export type COMPANY_MANAGER = 1;
export type SALES_MANAGER = 2;
export type CANDIDATE = 3;
export type SUPER_ADMIN = 99;

export const COMPANY_MANAGER_ID: COMPANY_MANAGER = 1;
export const SALES_MANAGER_ID: SALES_MANAGER = 2;
export const CANDIDATE_ID: CANDIDATE = 3;

export type UserGroup = COMPANY_MANAGER | SALES_MANAGER | CANDIDATE | 99;

export interface LoginRequest {
    readonly username: string;
    readonly password: string;
}

export interface LoginResponse {
    token: string,
}

export interface LoginFailResponse {
    non_field_errors?: string[],
    username?: string[],
    password?: string[],
}

export interface UserAvailability {
    id: string,
    name: string
}

export interface CurrentUser {
    id: string,
    is_superuser: boolean,
    groups: Array<COMPANY_MANAGER | SALES_MANAGER | CANDIDATE>
    full_name: string,
    nationality: null | string,
    image: string | null,
    gender: null | string,
    phone_number: null | string,
    city: null | string,
    birth_date: null | string,
    skills: FunctionSkill[],
    tools: FunctionSkill[],
    user: number, // user.id
    availability: UserAvailability // uuid
    educations: UserEducation[],
    role_name?: string, // custom added
}
