/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import PageTopBar from '../../../components/common/PageTopBar';
import ImageUpload from '../../resume/components/form/ImageUpload';
import ResumeInput from '../../resume/components/form/ResumeInput';
import CompanyService from '../services/company.service';
import notifications from '../../../utils/notifications';
import { GENERAL_SUCCESS_MESSAGE } from '../../../utils/constants';
import { ERROR, SUCCESS } from '../../../dto/constants.dto';
import { PostRequestProps } from '../../../lib/axios/request';
import { usePrompt } from '../../../hooks/usePrompt';
import LoadingOverlay from '../../../components/layout/LoadingOverlay';


interface Props {
    className?: string;
}

function CompaniesForm({ className = '' }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
    const { companyId } = useParams();

    const [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [currentImage, setCurrentImage] = useState<string>('');
    const [image, setImage] = useState<File | null>(null);

    const navigate = useNavigate();

    const { triggerPrompt } = usePrompt();

    const [errors, setErrors] = useState({
        name: '',
        image: '',
    });

    const resetErrors = () => {
        setErrors({
            name: '',
            image: '',
        });
    };
    const handleSuccess = ({ response }: PostRequestProps) => {
        if (response.data.id) {
            navigate(`/companies/${response.data.id}/edit`);
        } else {
            navigate('/companies');
        }
        notifications.success(GENERAL_SUCCESS_MESSAGE);
    };
    const handleError = ({ response }: PostRequestProps) => {
        setErrors({
            name: (response?.data?.name ? response?.data?.name[0] : ''),
            image: (response?.data?.image ? response?.data?.image[0] : ''),
        });
    };

    const updateCompany = async () => {
        if (!companyId) {
            return;
        }
        setIsLoading(true);

        const { response, status } = await CompanyService.update({
            id: companyId, name, image,
        });
        setIsLoading(false);

        if (status === ERROR) {
            handleError({ response, status });
        } else {
            handleSuccess({ response, status });
        }
    };

    const onSaveAction = async () => {
        resetErrors();
        if (companyId) {
            await updateCompany();
            return;
        }

        setIsLoading(true);
        const { response, status } = await CompanyService.insert({ image, name });
        setIsLoading(false);


        if (status === ERROR) {
            handleError({ response, status });
        } else {
            handleSuccess({ response, status });
        }
    };

    const onDeleteAction = async () => {
        if (!companyId) {
            return;
        }
        setIsDeleteLoading(true);
        const { response, status } = await CompanyService.delete(companyId);
        setIsDeleteLoading(false);
        if (status === SUCCESS) {
            handleSuccess({ response, status });
        } else {
            handleError({ response, status });
        }
    };

    const imageSelect = (file: File | null) => {
        setImage(file);
    };


    const deletePrompt = () => {
        triggerPrompt({
            title: 'Warning',
            text: 'Are you sure you want to delete this company?',
            onSubmit: onDeleteAction,
        });
    };


    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') {
            return;
        }
        onSaveAction();
    };

    useEffect(() => {
        if (!companyId) {
            return;
        }
        setIsCompanyLoading(true);
        CompanyService.show(companyId).then((response) => {
            setIsCompanyLoading(false);
            if (!response) {
                navigate('/');
                return;
            }
            setCurrentImage(response.image);
            setName(response.name);
        });
    }, [companyId]);

    if (isCompanyLoading) {
        return <LoadingOverlay />;
    }

    return (
        <Layout>
            <div className="content line mobilePadding">
                <PageTopBar
                    onDeleteAction={() => deletePrompt()}
                    onSaveAction={() => onSaveAction()}
                    backArrowUrl="/companies"
                    isSaveLoading={isLoading}
                    isDeleteLoading={isDeleteLoading}
                    isEdit={(companyId !== undefined)}
                />
                <div className="align-items-center d-flex justify-content-center mt-5">
                    <ImageUpload
                        onChange={(file) => imageSelect(file)}
                        previewImage={currentImage}
                        errorText={errors.image ?? ''}

                    />
                </div>

                <div style={{ maxWidth: 720 }} className="me-auto ms-auto pb-3 pt-5">
                    <ResumeInput
                        required
                        label="Company Name"
                        className="formGridItem"
                        value={name}
                        autoFocus
                        name="company_name"
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        placeholder="Bosned"
                        errorText={errors.name ?? ''}
                    />
                </div>
            </div>

        </Layout>
    );
}

export default CompaniesForm;