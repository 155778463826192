/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import notifications from '../utils/notifications';
import { GENERAL_ERROR_MESSAGE, GENERAL_VALIDATION_FAIL_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from '../utils/constants';

const showToastifyNotification = (error: any) => {
    if (!error.response || !error.response.data) {
        return;
    }
    if (error.response.data.detail) {
        notifications.error(error.response.data.detail);
    }
};

const handleValidationReponseErorr = (error: any) => {
    if (error.response.data && error.response.data[0]) {
        notifications.error(error.response.data[0]);
        return;
    }
    if (error.response.data.status) {
        notifications.warning(error.response.data.status);
        return;
    }
    notifications.warning(GENERAL_VALIDATION_FAIL_MESSAGE);
};

const handleServerError = (error: any) => {
    notifications.error(UNEXPECTED_ERROR_MESSAGE, 5000);
    Sentry.captureMessage('Server Error happened on Espelavo. Backend should have received and error in their log..');
};

export const errorLog = (error: any) => {
    if (error.response?.status === 400) {
        handleValidationReponseErorr(error);
        return;
    }
    if (error.response?.status === 409) {
        const msg = (error.response.data?.status ? error.response.data.status : GENERAL_ERROR_MESSAGE);
        Sentry.captureMessage(msg);
        notifications.error(msg);
        return;
    }
    if (error.response?.status === 401) {
        return;
    }
    if (error.response?.status === 404) {
        notifications.error('Requested resource not found');
        return;
    }

    const errorCode = String(error.response?.status);
    if (errorCode.startsWith('50', 0)) {
        handleServerError(error);
        return;
    }
    showToastifyNotification(error);
};

