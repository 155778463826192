import { PostRequestProps, sendPostRequest } from '../../../lib/axios/request';

const RequestAcces = {

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-bd8c54f1-a45a-467b-9d01-6f98360c3b82 | Link}
     */
    async sendRequest(email: string): Promise<PostRequestProps> {
        return await sendPostRequest('/request-access/', { email });
    },

};

export default RequestAcces;