import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import AccessService from '../services/access.service';
import { AccessItemDo, AccessListDo } from '../ts/access.interfaces';
import TableRowAction from '../../../components/table/TableRowAction';
import TableNoResults from '../../../components/table/TableNoResults';
import ImageHolder from '../../../components/user/ImageHolder';
import Pagination from '../../../components/table/Pagination';
import { PageUriType } from '../../../dto/pagination.dto';
import TableHeader from '../../../components/table/TableHeader';


interface Props {
    className?: string;
}

function AccessIndex({ className = '' }: Props) {
    const [accessees, setAccesses] = useState<AccessListDo>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const onEditClick = (access: AccessItemDo) => {
        navigate(`/access/${access.id}/edit`);
    };

    const fetch = async (value: string = '', linkUri: PageUriType = null) => {
        setIsLoading(true);
        AccessService.index({ search: value, pageUrl: linkUri }).then((response) => {
            setIsLoading(false);
            setAccesses(response);
        });
    };

    const searchAction = (value: string) => {
        fetch(value);
    };

    const paginationAction = (link: string) => {
        fetch('', link);
    };
    const getAvailabilityClass = (availability: string) => {
        switch (availability) {
        case ('Available'): {
            return 'available';
        }
        case ('Busy'): {
            return 'busyStatus';
        }
        case ('Archived'): {
            return 'archived';
        }
        default: {
            return 'available';
        }
        }
    };

    useEffect(() => {
        fetch();
    }, []);


    return (
        <Layout>
            <div className="line ">
                <div className="content  mobilePadding mt-lg-4">
                    <div
                        className="align-items-center d-flex flex-row flex-sm-row flex-wrap justify-content-sm-between justify-content-start "
                    >
                        <div className="">
                            <p className="darkGrey mb-1 profileName">
                                Access Management
                            </p>
                        </div>
                        <div
                            className="d-flex fullWithMobile justify-content-center justify-content-sm-start mt-3 mt-sm-0"
                        >
                            <div className="mt-3 mt-sm-0">
                                <Link to="/candidates/create" className="addCandidateBtn">
                                    Add new User
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <div className="accessGrid greybg">
                        <div className="usersGridLabel">
                            <p className="mb-0 profileNumber">ID</p>
                        </div>
                        <div className="usersGridLabel">
                            <p className="mb-0 profileNumber">Name</p>
                        </div>
                        <div className="d-none d-sm-flex usersGridLabel">
                            <p className="mb-0 profileNumber">Role</p>
                        </div>
                        <div className="d-none d-sm-flex usersGridLabel">
                            <p className="mb-0 profileNumber">Companies</p>
                        </div>
                        <div className="usersGridLabel">
                            <p className="mb-0 profileNumber">Status</p>
                        </div>
                        <div className="usersGridLabel">
                            <TableHeader
                                isLoadingOutside={isLoading}
                                onInputChange={(value) => searchAction(value)}
                                title="Access"
                            />
                        </div>
                    </div>
                    {accessees?.results?.map((access) => (
                        <div key={access.user_id}>
                            <div className="accessGrid">
                                <div className="usersGridItem id-row">
                                    <p className="darkGrey mb-0 profileNumber">{access.user_id}</p>
                                </div>
                                <div className="usersGridItem">
                                    <div className="align-items-center d-flex justify-content-start">
                                        <ImageHolder
                                            image={access.image}
                                            className="companyImgOval d-none d-sm-block me-3"
                                            name={access.full_name}
                                            initialsClassName="initials-holder accessImgOvalInitials companyImgOval d-none d-sm-block me-3"
                                        />

                                        <Link to={`/access/${access.id}/edit`} className="no-href-style">
                                            <span className="bold peopleFullname ac-f-name">
                                                {access.full_name.length !== 0 ? (
                                                    access.full_name
                                                ) : ('-')}
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="d-none d-sm-flex usersGridItem">
                                    <p className="darkGrey mb-0 text-capitalize">{access.stringified_groups}</p>
                                </div>
                                <div className="d-none d-sm-flex usersGridItem">
                                    <p className="darkGrey mb-0">{access.stringified_companies}</p>
                                </div>
                                <div className="usersGridItem">
                                    <div
                                        className={`activeBtn ${getAvailabilityClass(access.availability)} d-none d-sm-block`}
                                    >
                                        {access.availability}
                                    </div>
                                    <div
                                        className={`activeStatusCircle ${getAvailabilityClass(access.availability)}  d-block d-sm-none`}
                                    />
                                </div>
                                <TableRowAction onEditClick={() => onEditClick(access)} />
                            </div>
                            <div className="borderBtm" />
                        </div>
                    ))}
                    {accessees?.count === 0 && (
                        <TableNoResults />
                    )}

                    <Pagination
                        next={accessees?.next}
                        count={accessees?.count}
                        previous={accessees?.previous}
                        onNextClick={(link) => paginationAction(link)}
                        onPreviousClick={(link) => paginationAction(link)}
                    />
                </div>
            </div>
        </Layout>
    );
}

export default AccessIndex;