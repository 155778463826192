/**/
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import PrimaryButton from '../../../components/common/PrimaryButton';
import GroupService, { Group } from '../../../services/group.service';
import CompanyService from '../../companies/services/company.service';
import { CompaniesDo, CompanyDo } from '../../companies/ts/comapnies-index.interface';
import ImageHolder from '../../../components/user/ImageHolder';
import Input from '../../../components/common/Input';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import CandidatesService from '../services/candidates.service';
import { SUCCESS } from '../../../dto/constants.dto';
import notifications from '../../../utils/notifications';
import { GENERAL_SUCCESS_MESSAGE } from '../../../utils/constants';
import { PostRequestProps } from '../../../lib/axios/request';
import { CANDIDATE_ID } from '../../authentication/ts/auth_interfaces';
import useUser from '../../authentication/hooks/useUser';


interface Props {
    className?: string;
}

function CandidatesForm({ className = '' }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<number[]>([]);

    const [isGroupsLoading, setIsGroupsLoading] = useState<boolean>(false);
    const [groups, setGroups] = useState<Group[]>([]);

    const [errors, setErrors] = useState({
        name: '',
        email: '',
    });

    const [isCompaniesLoading, setIsCompaniesLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<CompaniesDo>({
        count: 0,
        previous: null,
        next: null,
        results: [],
    });

    const { user, isUserLoading } = useUser();

    const navigate = useNavigate();

    const onCancel = () => {
        navigate('/access');
    };

    /* Only superadmins can create other types of users */
    const filterGroups = (groupsList: Group[]): Group[] => {
        if (user?.is_superuser) {
            return groupsList;
        }
        return groupsList.filter((group) => Number(group.id) === CANDIDATE_ID);
    };

    const getGroups = async () => {
        setIsGroupsLoading(true);
        GroupService.getGroups().then((groupsList) => {
            const filteredGroupList = filterGroups(groupsList);
            setGroups(filteredGroupList);
            setIsGroupsLoading(false);
        });
    };

    const getCompanies = async () => {
        setIsCompaniesLoading(true);
        CompanyService.index({}).then((companiesResponse) => {
            setCompanies(companiesResponse);
            setIsCompaniesLoading(false);
        });
    };
    /* Used to select "Candidate" as default role on create */
    const selectDefaultRoleOnCreate = () => {
        setSelectedGroups([CANDIDATE_ID]);
    };

    useEffect(() => {
        // wait until user is loaded, then fetch groups and companies
        if (isUserLoading) {
            return;
        }
        getGroups().then(() => {
            getCompanies();
            selectDefaultRoleOnCreate();
        });
    }, [isUserLoading]);

    const onCompanySelect = (company: CompanyDo, e: React.ChangeEvent<HTMLInputElement>) => {
        const currentSelectedCompanies = selectedCompanies;
        if (e.target.checked) {
            currentSelectedCompanies.push(company.id);
        } else {
            const index = currentSelectedCompanies.indexOf(company.id);
            if (index !== -1) {
                currentSelectedCompanies.splice(index, 1);
            }
        }
        setSelectedCompanies(currentSelectedCompanies);
    };


    const roleSelect = (group: Group) => {
        const arr = [];
        arr.push(Number(group.id));
        setSelectedGroups(arr);
    };

    const handleSuccess = () => {
        navigate('/access');
        notifications.success(GENERAL_SUCCESS_MESSAGE);
    };

    const handleError = ({ response }: PostRequestProps) => {
        if (response.data.full_name) {
            setErrors({ ...errors, name: response.data.full_name[0] });
        }
        if (response.data.email) {
            setErrors({ ...errors, email: response.data.email[0] });
        }
    };

    const onSave = async () => {
        setIsLoading(true);
        const { response, status } = await CandidatesService.create({
            email,
            full_name: name,
            groups: selectedGroups,
            companies: selectedCompanies,
        });
        setIsLoading(false);
        if (status === SUCCESS) {
            handleSuccess();
            return;
        }
        handleError({ response, status });
    };

    return (
        <Layout>
            <div className="line">
                <div
                    className="content d-flex d-sm-flex flex-column flex-sm-row justify-content-between justify-content-sm-between mb-lg-4 mb-sm-4 mobilePadding mt-lg-4 mt-sm-4"
                >
                    <div>
                        <div>
                            <p className="darkGrey mb-1 profileName">New candidate</p>
                        </div>
                    </div>
                    <div className="mt-3 mt-sm-0">
                        <div className="d-flex flex-row justify-content-start">
                            <PrimaryButton
                                text="Create"
                                onClick={() => onSave()}
                                isLoading={isLoading}
                                className="saveBtn"
                            />
                            <PrimaryButton
                                disabled={isLoading}
                                text="Cancel"
                                onClick={() => onCancel()}
                                className="cancelBtn ms-3"
                            />
                        </div>
                    </div>
                </div>
                <div className="content d-flex mobilePadding ConmobilePadding flex-row roleContainer">
                    <span className="fw-bold me-3 mt-4 mt-sm-0">
                        Role
                        {' '}
                        <LoadingSpinner isLoading={isGroupsLoading} />
                    </span>

                    <div className="d-flex flex-wrap mt-4 mt-sm-0">
                        {groups.map((group, index) => (
                            <div className="radioBtn" key={`group_${group.id}`}>
                                <div className="radioBtn">
                                    <label className="form-check-label ms-3" htmlFor={`group_${group.id}`}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            disabled={!user?.is_superuser}
                                            checked={selectedGroups.includes(Number(group.id))}
                                            onChange={() => roleSelect(group)}
                                            name="candidate_group"
                                            id={`group_${group.id}`}
                                            value="option1"
                                        />
                                        {group.name.replace(/_/g, ' ')}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                <div
                    className="content d-flex d-sm-flex flex-column flex-sm-row mobilePadding mt-lg-3 mt-md-3 mt-sm-3 mb-3 mt-3 mb-sm-0 mt-sm-0 pb-0   "
                >
                    <div className="d-flex flex-row flex-sm-column me-5">
                        <label
                            className="defaultGreyText "
                        >
                            ID
                        </label>
                        <span
                            className="defaultGreyText ms-2 ms-sm-0 mt-sm-4"
                        >
                            -
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <label
                            className="defaultGreyText required"
                        >
                            Personal Details
                        </label>
                        <div className="mb-3 me-sm-3 mt-2 mt-sm-4">
                            <Input
                                autoFocus
                                value={name}
                                name="name"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Full Name"
                                className="custom-input"
                                wrapperClassName="d-grid"
                                errorText={errors?.name ?? ''}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-column">
                        <label className="defaultGreyText required">E-mail</label>
                        <div className="mt-2 mt-sm-4">
                            <Input
                                value={email}
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="your@email.com"
                                className="custom-input"
                                wrapperClassName="d-grid"
                                errorText={errors?.email ?? ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="content mobilePadding">
                    <hr className="horizontalLine mb-0 mt-0" />
                    <div className="mb-4 mt-4">
                        <p className="bold  pb-2 pb-sm-2 ps-sm-4 pt-2 pt-sm-2">
                            Companies
                            {' '}
                            <LoadingSpinner isLoading={isCompaniesLoading} />
                        </p>
                    </div>

                    {companies?.results.map((company) => (
                        <div key={`company_div_${company.id}`}>
                            <hr className="horizontalLine" />
                            <div className="align-items-center checkBoxContainer d-flex form-check">
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    id={`chk_company${company.id}`}
                                    onChange={(e) => onCompanySelect(company, e)}
                                />
                                <ImageHolder
                                    image={company.image}
                                    name={company.name}
                                    className="companyImgOval me-3 ms-3"
                                    initialsClassName="initials-holder companyImgOval companyImgOvalInitials me-3 ms-3"
                                />
                                <label
                                    htmlFor={`chk_company${company.id}`}
                                    className="checkboxLabel darkGrey"
                                >
                                    {company.name}
                                </label>
                            </div>
                        </div>
                    ))}


                    <hr className="horizontalLine" />
                </div>
            </div>
        </Layout>
    );
}

export default CandidatesForm;