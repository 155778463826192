import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserGroup } from '../../features/authentication/ts/auth_interfaces';
import useUser from '../../features/authentication/hooks/useUser';

export type IconList = 'person' | 'palette' | 'trophy' | 'hierarchy';

interface Props {
    url: string;
    text: string;
    icon: IconList;
    roles?: UserGroup[];
}

function SidebarLink({
    url, text, roles = [], icon,
}: Props) {
    const { isUserLoading, userRole, user } = useUser();
    const location = useLocation();

    const isUrlActiveA = (): boolean => {
        if (location.pathname !== '/' && url === '/') {
            return false;
        }
        return location.pathname.startsWith(url);
    };

    const isUrlActive = useMemo(() => isUrlActiveA(), [url]);

    if (isUserLoading || !userRole) {
        return null;
    }

    if (!user?.is_superuser && !roles.includes(userRole)) {
        return null;
    }

    return (
        <Link
            to={url}
            className={`navigationLink ${isUrlActive ? 'activeLink' : ''}`}
        >
            <span className={`icon-helper sidebar ${icon}`} />
            <p className="mb-0 navText">{text}</p>
        </Link>
    );
}

export default SidebarLink;