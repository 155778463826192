import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import TableHeader from '../../../components/table/TableHeader';
import TableRowAction from '../../../components/table/TableRowAction';
import CompanyService from '../services/company.service';
import { CompaniesDo, CompanyDto } from '../ts/comapnies-index.interface';
import TableNoResults from '../../../components/table/TableNoResults';
import TableLoading from '../../../components/table/TableLoading';
import ImageHolder from '../../../components/user/ImageHolder';
import Pagination from '../../../components/table/Pagination';
import { PageUriType } from '../../../dto/pagination.dto';

export default function CompaniesIndex() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<CompaniesDo>({
        count: 0,
        next: null,
        previous: null,
        results: [],
    });

    const navigate = useNavigate();

    const onEditClick = (company: CompanyDto) => {
        navigate(`/companies/${company.id}/edit`);
    };

    const fetchCompanies = async (value: string = '', pageUrl: PageUriType = null) => {
        setIsLoading(true);
        CompanyService.index({ search: value, pageUrl }).then((companiesRes) => {
            setCompanies(companiesRes);
            setIsLoading(false);
        });
    };

    const searchAction = (value: string) => {
        if (value.length < 1) {
            return;
        }
        fetchCompanies(value);
    };


    const paginationAction = (link: string) => {
        fetchCompanies('', link);
    };

    useEffect(() => {
        fetchCompanies();
    }, []);

    return (
        <Layout>
            <div className="line ">
                <div className="content mt-lg-4">
                    <div
                        className="align-items-center d-flex flex-row flex-sm-row flex-wrap justify-content-sm-between justify-content-start mobilePadding"
                    >
                        <div className="">
                            <p className="darkGrey mb-1 profileName">Companies</p>
                        </div>
                        <div
                            className="d-flex fullWithMobile justify-content-center justify-content-sm-start mt-sm-0"
                        >
                            <div className=" mt-sm-0">
                                <Link to="/companies/create" className="addCandidateBtn">
                                    Add new Company
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <TableHeader useCompanyLayout onInputChange={(value) => searchAction(value)} />
                <div>

                    {isLoading && (
                        <TableLoading isLoading />
                    )}
                    {companies?.results.map((company, index) => (
                        <div key={`${company.id}_wrapper`}>
                            <div className="usersGrid">
                                <div className="usersGridItem">
                                    <div className="align-items-center d-flex justify-content-start">
                                        <ImageHolder
                                            initialsClassName="initials-holder companyImgOval candidatesInitialsHolder me-3"
                                            className="companyImgOval me-3"
                                            image={company.image}
                                            name={company.name}
                                        />
                                        <Link to={`/companies/${company.id}/edit`} className="no-href-style">
                                            <span className="bold peopleFullname">
                                                {company.name.length !== 0 ? (
                                                    company.name
                                                ) : ('-')}
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="d-none d-sm-flex usersGridItem">
                                    <p className="darkGrey mb-0">{company.managers_label}</p>
                                </div>
                                <div className="d-none d-sm-flex usersGridItem">
                                    <p className="darkGrey mb-0">{company.total_candidates}</p>
                                </div>
                                <TableRowAction onEditClick={() => onEditClick(company)} />
                            </div>
                            <div className="borderBtm" key={`${company.id}_border_btm`} />
                        </div>
                    ))}

                    {(companies?.count === 0 && !isLoading) && (
                        <TableNoResults />
                    )}
                    <Pagination
                        next={companies?.next}
                        count={companies?.count}
                        previous={companies?.previous}
                        onNextClick={(link) => paginationAction(link)}
                        onPreviousClick={(link) => paginationAction(link)}
                    />
                </div>
            </div>

        </Layout>
    );
}
