/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useRef, useState } from 'react';
import ImageHolder from '../../../../components/user/ImageHolder';

interface Props {
    onChange: (file: File | null) => void,
    errorText?: string,
    previewImage?: string | null,
    roundLayout?: boolean,
}

function ImageUpload({
    onChange, errorText = '', previewImage = null, roundLayout = false,
}: Props) {
    const pictureRef = useRef<HTMLInputElement>(null);

    const [imgPreview, setImgPreview] = useState<string>('');

    useEffect(() => {
        if (!previewImage) {
            return;
        }
        setImgPreview(previewImage);
    }, [previewImage]);

    const openPrompt = () => {
        pictureRef.current?.click();
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setImgPreview(URL.createObjectURL(event.target.files[0]));
            onChange(event.target.files[0] ?? null);
        }
    };

    if (roundLayout) {
        return (
            imgPreview ? (
                <div
                    onClick={openPrompt}
                    className="align-items-center d-flex justify-content-center me-4 ms-2 ovalImg blue"
                >
                    <ImageHolder
                        image={imgPreview}
                        className="ovalImg"
                        initialsClassName="ovalImg"
                        name="..."
                    />
                    <input
                        type="file"
                        className="d-none"
                        accept="image/*"
                        ref={pictureRef}
                        onChange={(e) => onInputChange(e)}
                    />
                </div>
            ) : (
                <div
                    onClick={openPrompt}
                    className="align-items-center d-flex justify-content-center me-4 ms-2 ovalImg blue"
                >
                    <i className="fa-camera fas text-white" />
                    <input
                        type="file"
                        className="d-none"
                        accept="image/*"
                        ref={pictureRef}
                        onChange={(e) => onInputChange(e)}
                    />
                </div>
            )
        );
    }

    return (
        <div
            className="align-items-center d-flex dragAndDropPicture flex-wrap justify-content-center cursor-pointer relative"
            onClick={openPrompt}
        >
            <div className="align-items-center d-flex justify-content-center uploadPicture">
                {imgPreview ? (
                    <img
                        src={imgPreview}
                        alt="User"
                        className="resume-cv-image"
                    />
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="cammeraICon"
                    >
                        <path
                            d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 384c-53 0-96-43-96-96s43-96 96-96s96 43 96 96s-43 96-96 96z"
                        />
                    </svg>
                )}
            </div>
            <small className="error-text input-text">
                {errorText}
            </small>
            <div>
                <p className="defaultGreyText me-2 me-sm-5 ms-2 ms-sm-5 mt-3 ">
                    <strong className="darkGrey">Click to upload </strong>
                    <span>(max. 800x800px)</span>
                    <br />
                    PNG, JPEG/JPG or WEBP
                </p>
            </div>
            <input
                type="file"
                className="d-none"
                accept="image/*"
                ref={pictureRef}
                onChange={(e) => onInputChange(e)}
            />

        </div>
    );
}

export default ImageUpload;