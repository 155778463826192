import { CANDIDATE, COMPANY_MANAGER, SALES_MANAGER } from '../ts/auth_interfaces';

const getUserGroupById = (id: | COMPANY_MANAGER | SALES_MANAGER | CANDIDATE): string => {
    switch (id) {
    case 1: {
        return 'Company Manager';
    }
    case 2: {
        return 'Sales Manager';
    }
    case 3: {
        return 'Candidate';
    }
    default: {
        return 'Admin';
    }
    }
};

const getUserInitials = (fullName: string) => ((fullname) => fullname.map((n, i) => (i === 0 || i === fullname.length - 1) && n[0]).filter((n) => n).join(''))(fullName.split(' '));

export {
    getUserGroupById,
    getUserInitials,
};
export default {
    getUserGroupById,
    getUserInitials,
};