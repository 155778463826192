import React from 'react';
import PrimaryButton from './PrimaryButton';
import BackArrowLink from './BackArrowLink';


interface Props {
    onSaveAction: () => void;
    isSaveLoading?: boolean;
    isDeleteLoading?: boolean;
    isEdit?: boolean;
    onDeleteAction: () => void;
    backArrowUrl?: string;
}

function PageTopBar({
    onSaveAction,
    backArrowUrl = '/',
    isSaveLoading = false,
    isEdit = false,
    onDeleteAction,
    isDeleteLoading = false,
}: Props) {
    return (
        <div className="align-items-center d-flex justify-content-between">
            <BackArrowLink to={backArrowUrl} />
            <div className="d-flex">
                {(isEdit && onDeleteAction) && (
                    <PrimaryButton
                        text="Delete"
                        onClick={onDeleteAction}
                        className="cancelBtn removeBtn companyCancelBtn"
                        disabled={isDeleteLoading}
                    />
                )}
                <PrimaryButton text="Save" onClick={onSaveAction} className="saveBtn" isLoading={isSaveLoading} />
            </div>
        </div>
    );
}

export default PageTopBar;