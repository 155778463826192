/*eslint-disable*/
// const success = (msg: string) => toast.success(msg);
import { toast } from 'react-toastify';
import { ToastOptions, ToastPosition } from 'react-toastify/dist/types';

const config: ToastOptions = {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light'
};

const success = (msg: string) => {
    toast.success(msg, config);
};

const error = (msg: string, autoClose: false | number = 3000) => {
    if (autoClose !== 3000) {
        config.autoClose = autoClose;
    }
    toast.error(msg, config);
};

const warning = (msg: string, position?: ToastPosition) => {
    if (position) {
        config.position = position;
    }
    toast.warning(msg, config);
};


export default {
    success,
    error,
    warning
};
