import React from 'react';


interface Props {
    isLoading: boolean;
    className?: string;
}

function LoadingSpinner({ isLoading = false, className = '' }: Props) {
    if (!isLoading) {
        return null;
    }
    return (
        <span className={`spinner-border spinner-border-sm ${className}`} role="status" aria-hidden="true" />
    );
}

export default LoadingSpinner;