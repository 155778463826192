import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { StoreContext } from '../../../store.context';
import LoadingSpinner from '../../../components/common/LoadingSpinner';

function LogoutButtonComponent() {
    const { authStore } = useContext(StoreContext);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const logOutAction = () => {
        setIsLoading(true);
        authStore.logout().then((logoutResponse) => {
            setIsLoading(false);
            window.location.href = '/';
        });
    };

    return (
        <button type="button" onClick={() => logOutAction()} className="logOutBtn v2">
            {isLoading ? (
                <LoadingSpinner isLoading={isLoading} />
            ) : (
                <i
                    className="fa-sign-out-alt fas"
                />
            )}
            <span className="logoutText">Log out</span>
        </button>
    );
}

const LogoutButton = observer(LogoutButtonComponent);
export default LogoutButton;
