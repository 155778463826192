/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */

import axios, { AxiosRequestConfig } from 'axios';
import { ERROR, SUCCESS } from '../../dto/constants.dto';
import { errorLog } from '../../services/errorhandler.service';

export interface PostRequestProps {
    response: any;
    status: 'success' | 'error' | string;
}

export const sendPostRequest = async (url: string, data: any | null, config?: AxiosRequestConfig): Promise<PostRequestProps> => {
    const responseData = await axios.post(url, data, config)
        .then((response) => ({ response, status: SUCCESS }))
        .catch((error) => {
            errorLog(error);
            return { response: error.response, status: ERROR };
        });
    return { response: responseData.response, status: responseData.status };
};

export const sendPutRequest = async (url: string, data: any | null, config?: AxiosRequestConfig): Promise<PostRequestProps> => {
    const responseData = await axios.put(url, data, config)
        .then((response) => ({ response, status: SUCCESS }))
        .catch((error) => {
            errorLog(error);
            return { response: error.response, status: ERROR };
        });
    return { response: responseData.response, status: responseData.status };
};

export const sendGetRequest = async (url: string): Promise<any> => await axios.get(url)
    .then((response) => response.data)
    .catch((error) => {
        errorLog(error);
        return null;
    });

export const sendDeleteRequest = async (url: string, data: any | null): Promise<PostRequestProps> => {
    const responseData = await axios.delete(url, { data })
        .then((response) => ({ response, status: 'success' }))
        .catch((error) => {
            errorLog(error);
            return { response: error.response, status: 'error' };
        });
    return { response: responseData.response, status: responseData.status };
};

export const sendPatchRequest = async (url: string, data: any | null): Promise<any> => {
    const responseData = await axios.patch(url, data)
        .then((response) => ({ response, status: 'success' }))
        .catch((error) => {
            errorLog(error);
            return { response: error.response, status: 'error' };
        });
    return { response: responseData.response, status: responseData.status };
};

export const sendPostRequestWithFile = async (url: string, data: FormData): Promise<PostRequestProps> => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    return await sendPostRequest(url, data, config);
};

export const sendPutRequestWithFile = async (url: string, data: FormData): Promise<PostRequestProps> => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    return await sendPutRequest(url, data, config);
};

export const createAxiosResponseInterceptor = () => {
    axios.interceptors.request.use(
        async (config) => {
            // Do something before request is sent
            // @ts-ignore
            if (config?.headers.Authorization === 'force-no-token') {
                return config;
            }
            const token = localStorage.getItem('access_token');
            if (token === null) {
                console.warn('%cNo token found! (1)', 'color:red; font-size:15px;');
                return config;
            }
            // @ts-ignore
            config.headers.Authorization = `Token ${token}`;
            // @ts-ignore
            return config;
        },
        (error) => Promise.reject(error),
    );
    axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error?.response?.data?.detail === 'Invalid token.') {
                localStorage.removeItem('access_token');
                window.location.href = '/';
                return Promise.reject(error);
            }
            return Promise.reject(error);
        },
    );
};
