/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/**
 * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-65a760f0-664c-4066-a4d4-86e60df11674 | Link}
 * @Description Get Currently authenticated user by bearer otken in header
 */
import UserHelper from './UserHelper';
import { CurrentUser } from '../ts/auth_interfaces';
import { sendGetRequest } from '../../../lib/axios/request';

const getCurrentUser = async (): Promise<CurrentUser> => {
    console.count('Fetching user form the backend: Number of times');
    const data = await sendGetRequest('/current-user/');
    return data;
};


const getUserByAccessId = async ({ accessId }: { accessId: string }): Promise<CurrentUser> => await sendGetRequest(`/access/${accessId}/edit-profile/`);


const userFieldMutator = (user: CurrentUser | null): CurrentUser | null => {
    if (!user) {
        return null;
    }

    user.role_name = UserHelper.getUserGroupById(user.groups[0]);
    return user;
};

export { getCurrentUser, userFieldMutator, getUserByAccessId };

export default {
    getCurrentUser,
    getUserByAccessId,
};
