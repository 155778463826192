/* eslint-disable no-undef, react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import CompanyService from '../services/company.service';
import notifications from '../../../utils/notifications';
import { GENERAL_SUCCESS_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from '../../../utils/constants';
import { ERROR, SUCCESS } from '../../../dto/constants.dto';
import { PostRequestProps } from '../../../lib/axios/request';
import LoadingOverlay from '../../../components/layout/LoadingOverlay';
import BackArrowLink from '../../../components/common/BackArrowLink';
import ImageHolder from '../../../components/user/ImageHolder';
import { CompanyDto } from '../ts/comapnies-index.interface';
import PrimaryButton from '../../../components/common/PrimaryButton';
import ImageUpload from '../../resume/components/form/ImageUpload';
import ResumeInput from '../../resume/components/form/ResumeInput';
import { usePrompt } from '../../../hooks/usePrompt';
import CompanyModal from '../components/CompanyModal';

type DisconnectType = 'candidate' | 'manager';

interface Props {
    className?: string;
}

function CompaniesEdit({ className = '' }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
    const [isModalCandidatesOpen, setIsModalCandidatesOpen] = useState<boolean>(false);
    const [isManagersModalOpen, setIsManagersModalOpen] = useState<boolean>(false);

    const { companyId } = useParams();

    const [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(false);
    const [company, setCompany] = useState<CompanyDto>();

    const [newImage, setNewImage] = useState<File | null>(null);
    const [isEditNameActive, setIsEditNameActive] = useState<boolean>(false);

    const [usersList, setUsersList] = useState<number[]>([]);

    const { triggerPrompt } = usePrompt();

    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        name: '',
        image: '',
    });

    const preloadData = (comp: CompanyDto | null) => {
        if (!comp) {
            return;
        }
        const candidatesIds = comp.candidates.map((item) => item.user_id);
        const managersIds = comp.managers.map((item) => item.user_id);
        const arrOfIds = candidatesIds.concat(managersIds);
        setUsersList(arrOfIds);
    };

    const fetchCompany = async () => {
        if (!companyId) {
            return null;
        }
        setIsCompanyLoading(true);
        return CompanyService.show(companyId).then((response) => {
            setIsCompanyLoading(false);
            if (!response) {
                navigate('/');
                return null;
            }
            setCompany(response);
            preloadData(response);
            return response;
        });
    };


    useEffect(() => {
        if (!companyId) {
            return;
        }
        fetchCompany();
    }, [companyId]);

    const updateUsersToAdd = (userId: number) => {
        const arr = [...usersList];
        if (arr.includes(userId)) {
            const index = arr.indexOf(userId);
            if (index !== -1) arr.splice(index, 1);
        } else {
            arr.push(userId);
        }
        setUsersList(arr);
    };

    const resetErrors = () => {
        setErrors({
            name: '',
            image: '',
        });
    };

    const handleSuccess = ({ response }: PostRequestProps, redirectToIndex = true) => {
        notifications.success(GENERAL_SUCCESS_MESSAGE);
        if (redirectToIndex) {
            // TODO uncomment
            // navigate('/companies');
            setUsersList([]);
            fetchCompany();
        } else {
            setUsersList([]);
            fetchCompany();
        }
    };

    const handleError = ({ response }: PostRequestProps) => {
        setErrors({
            name: (response?.data?.name ? response?.data?.name[0] : ''),
            image: (response?.data?.image ? response?.data?.image[0] : ''),
        });
    };

    const updateNewImage = async () => {
        if (!newImage || !company?.id) {
            return;
        }
        await CompanyService.update({ image: newImage, id: company.id, name: company?.name }).then((res) => {
            const { response, status } = res;
            if (status === SUCCESS) {
                return;
            }
            notifications.warning('Failed to update company image.');
        });
    };

    const onSaveAction = async (redirectToIndex = true) => {
        if (!company) {
            notifications.error(UNEXPECTED_ERROR_MESSAGE);
            return;
        }
        resetErrors();
        setIsLoading(true);

        const obj = {
            id: company.id, name: company.name, users: usersList,
        };
        const { response, status } = await CompanyService.updateWithUsers(obj);
        if (newImage) {
            await updateNewImage();
        }
        setIsLoading(false);
        if (status === ERROR) {
            handleError({ response, status });
        } else {
            handleSuccess({ response, status }, redirectToIndex);
        }
    };

    const setNewImageAction = (newImageObj: File | null) => {
        setNewImage(newImageObj);
    };

    const disconnectAction = (id: number) => {
        const discUsers = [...usersList];
        if (discUsers.includes(id)) {
            const index = discUsers.indexOf(id);
            if (index !== -1) discUsers.splice(index, 1);
        } else {
            discUsers.push(id);
        }
        setUsersList(discUsers);
    };

    const onCancelAction = () => {
        navigate('/companies');
    };

    const onDeleteAction = async () => {
        if (!companyId || isDeleteLoading) {
            return;
        }
        setIsDeleteLoading(true);
        const { response, status } = await CompanyService.delete(companyId);
        setIsDeleteLoading(false);
        if (status === SUCCESS) {
            handleSuccess({ response, status });
        } else {
            handleError({ response, status });
        }
    };

    const showEditNameAction = () => {
        setIsEditNameActive(!isEditNameActive);
    };

    const deletePrompt = () => {
        triggerPrompt({
            title: 'Warning',
            text: 'Are you sure you want to delete this company?',
            onSubmit: onDeleteAction,
        });
    };

    const handleKeyDownName = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') {
            return;
        }
        setIsEditNameActive(false);
    };

    if (isCompanyLoading) {
        return <LoadingOverlay />;
    }
    if (!company) {
        return <LoadingOverlay />;
    }
    return (
        <Layout>
            <div className="content line mobilePadding">
                <div
                    className="align-items-center d-flex d-sm-flex flex-row flex-wrap justify-content-sm-between justify-content-start mb-4"
                >
                    <div className="align-items-center d-flex flex-row">
                        <BackArrowLink to="/companies" />
                        <ImageUpload
                            previewImage={company.image}
                            onChange={(newImageObj) => setNewImageAction(newImageObj)}
                            roundLayout
                        />
                        <div className="company-name-wrapper">
                            <div className="company-name">

                                {(isEditNameActive) ? (
                                    <ResumeInput
                                        autoFocus
                                        value={company.name}
                                        onKeyDown={handleKeyDownName}
                                        name="name"
                                        className="formGridItem "
                                        onChange={(e) => setCompany({ ...company, name: e.target.value })}
                                        placeholder="Name"
                                    />
                                ) : (
                                    <h3 className="mb-1 profileName">{company.name}</h3>
                                )}

                                <button type="button" className="btn-no-style" onClick={() => showEditNameAction()}>
                                    <i className="fa-pen fa-xs fas ms-3 cursor-pointer" />
                                </button>
                            </div>
                            <p className="mb-0 profileNumber">
                                {company.total_candidates}
                                {' '}
                                Candidates
                            </p>
                        </div>
                    </div>

                    <div className="d-flex fullWithMobile justify-content-between mt-3 mt-sm-0">
                        <PrimaryButton
                            text="Save"
                            className="saveBtn"
                            isLoading={isLoading}
                            onClick={() => onSaveAction()}
                        />
                        <PrimaryButton
                            text="Delete"
                            disabled={isLoading}
                            onClick={() => deletePrompt()}
                            className="removeBtn ms-2"
                        />
                        <PrimaryButton
                            text="Cancel"
                            disabled={isLoading}
                            onClick={() => onCancelAction()}
                            className="cancelBtn ms-2"
                        />
                    </div>
                </div>
                <hr className="horizontalLine mb-0 mt-0 w-100" />
                <div className="mt-3 mt-md-5 p-0 p-md-2">
                    <div
                        className="align-items-center align-items-sm-center d-flex d-sm-flex justify-content-between justify-content-sm-between"
                    >
                        <p className="bold d-inline-block mb-0 peopleFullname">Company Managers</p>
                        <PrimaryButton
                            className="cancelBtn companyManagerAddBtn"
                            text="Add"
                            onClick={() => setIsManagersModalOpen(true)}
                        />
                    </div>
                    <hr className="horizontalLine" />

                    {company.managers.map((manager, index) => (
                        <div key={manager.user_id}>
                            <div
                                className="align-items-center connectedCandidate d-flex flex-wrap justify-content-between mb-5 mt-5"
                            >
                                <div className="align-items-center d-flex justify-content-between ms-4">
                                    <ImageHolder
                                        image={manager.image}
                                        name={manager.full_name}
                                        initialsClassName="initials-holder companyImgOval companyImgOvalInitials me-4"
                                        className="companyImgOval   me-4"
                                    />
                                    <span className="bold peopleFullname">{manager.full_name}</span>
                                </div>

                                <PrimaryButton
                                    onClick={() => disconnectAction(manager.user_id)}
                                    text={(!usersList.includes(manager.user_id) ? 'Cancel Action' : 'Disconnect')}
                                    className={`btn-no-style disconnectText ${!usersList.includes(manager.user_id) ? 'disconnect' : 'cancel'}`}
                                />

                            </div>
                            <hr className="horizontalLine" />
                        </div>
                    ))}
                    {company.managers.length === 0 ? (
                        <p>
                            This company has no managers.
                        </p>
                    ) : null}
                </div>
                <div className="mt-3 mt-md-5 p-2 p-md-2">
                    <div
                        className="align-items-center align-items-sm-center d-flex d-sm-flex justify-content-between"
                    >
                        <p className="bold d-inline-block mb-0 peopleFullname">Candidates</p>
                        <PrimaryButton
                            className="cancelBtn companyManagerAddBtn"
                            text="Add"
                            onClick={() => setIsModalCandidatesOpen(true)}
                        />
                    </div>
                    <hr className="horizontalLine" />
                    {company.candidates.map((candidate, index) => (
                        <div key={candidate.user_id}>
                            <div
                                className="align-items-center connectedCandidate d-flex flex-wrap justify-content-between mb-5 mt-5"
                            >
                                <div className="align-items-center d-flex justify-content-between ms-4">
                                    <ImageHolder
                                        image={candidate.image}
                                        name={candidate.full_name}
                                        initialsClassName="initials-holder companyImgOval companyImgOvalInitials me-4"
                                        className="companyImgOval me-4"
                                    />
                                    <span className="bold peopleFullname">{candidate.full_name}</span>
                                </div>
                                <PrimaryButton
                                    onClick={() => disconnectAction(candidate.user_id)}
                                    text={(!usersList.includes(candidate.user_id) ? 'Cancel Action' : 'Disconnect')}
                                    className={`btn-no-style disconnectText ${!usersList.includes(candidate.user_id) ? 'disconnect' : 'cancel'}`}
                                />
                            </div>
                            <hr className="horizontalLine" />
                        </div>
                    ))}
                    {company.candidates.length === 0 ? (
                        <p>
                            This company has no candidates.
                        </p>
                    ) : null}

                </div>
            </div>
            <CompanyModal
                company={company}
                onSubmit={() => onSaveAction(false)}
                isOpen={isModalCandidatesOpen}
                addedUsersUpdated={(userId) => updateUsersToAdd(userId)}
                type="candidates"
                onClose={() => setIsModalCandidatesOpen(false)}
            />
            <CompanyModal
                company={company}
                onSubmit={() => onSaveAction(false)}
                isOpen={isManagersModalOpen}
                type="managers"
                addedUsersUpdated={(userId) => updateUsersToAdd(userId)}
                onClose={() => setIsManagersModalOpen(false)}
            />
        </Layout>
    );
}

export default CompaniesEdit;