/* eslint-disable no-param-reassign */
import {
    AccessIndexProps, AccessListDo, AccessListDto, AccessUpdate, UserAccessDto, 
} from '../ts/access.interfaces';
import {
    PostRequestProps, sendDeleteRequest, sendGetRequest, sendPatchRequest, 
} from '../../../lib/axios/request';

const AccessService = {

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-2e2330c3-2986-4d99-b0d7-2bbb48b784fd | Link}
     */
    async index({ search, pageUrl = null }: AccessIndexProps): Promise<AccessListDo> {
        let queryString = '';
        if (search) {
            queryString += `?search=${search}`;
        }

        const fullReqUri = (pageUrl ?? `/access/${queryString}`);
        const response = await sendGetRequest(fullReqUri) as unknown as AccessListDto;
        response?.results.forEach((item, index, theArray) => {
            // @ts-ignore
            item.stringified_companies = this.stringifyArray(item.companies);
            // @ts-ignore
            item.stringified_groups = this.stringifyArray(item.groups);
        });
        return response as unknown as AccessListDo;
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-0a6dd08f-735d-45ad-aedb-fee16d8abe58 | Link}
     * @Description
     */
    async getById(accessId: string): Promise<UserAccessDto> {
        return await sendGetRequest(`/access/${accessId}/`) as unknown as UserAccessDto;
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-67e1c128-166e-483f-a26e-f82c789e53f2 | Link}
     */
    async update(data: AccessUpdate): Promise<PostRequestProps> {
        return await sendPatchRequest(`/access/${data.accessId}/`, data);
    },

    stringifyArray(arr: Array<string>): string {
        if (arr.length === 0) {
            return '-';
        }
        return arr.join(', ');
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-0ae3e9bc-0513-443d-907b-2a44bff9283b | Link}
     */
    async updateUserPrivacy(showPrivacy: boolean, accessId: string): Promise<PostRequestProps> {
        return await sendPatchRequest(`/access/${accessId}/`, { show_privacy: showPrivacy });
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-8e97aa63-19cf-465c-9a18-e7259049a32a | Link}
     * @Description Delete Access by ID
     */
    async deleteUser(userId: number): Promise<PostRequestProps> {
        return await sendDeleteRequest(`/users/${userId}/`, null);
    },
};

export default AccessService;