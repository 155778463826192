import React, { ChangeEvent, KeyboardEvent } from 'react';
import useAutoFocus from '../../hooks/useAutoFocus';

interface Props {
    label?: string,
    value: string,
    name: string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void,
    placeholder: string,
    errorText?: string,
    className?: string,
    wrapperClassName?: string,
    autoFocus?: boolean,
}

function Input({
    label = '',
    onChange,
    name,
    placeholder,
    value,
    className = '',
    errorText = '',
    onKeyDown = () => null,
    autoFocus = false,
    wrapperClassName = '',
}: Props) {
    const { inputRef: nameAutoFocus } = useAutoFocus();

    return (
        <div className={wrapperClassName}>
            <input
                ref={(autoFocus ? nameAutoFocus : undefined)}
                onChange={(e) => onChange(e)}
                value={value}
                className={className}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                name={name}
            />
            {errorText && (
                <small className="error-text">{errorText}</small>
            )}
        </div>
    );
}

export default Input;