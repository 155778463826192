/* eslint-disable guard-for-in,no-restricted-syntax,no-undef */
import {
    PostRequestProps,
    sendGetRequest,
    sendPostRequest,
    sendPutRequest,
    sendPutRequestWithFile,
} from '../../../lib/axios/request';
import { SendEmailProps } from '../ts/send-email.interface';
import { ResumeCreateForm, UpdateUserImageRequest } from '../ts/resume.interface';

export const ResumeService = {


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-f4112e78-cd4b-43bf-a328-9e1ece58b260 | Link}
     */
    async updateResume(data: ResumeCreateForm, accessId: string | undefined): Promise<PostRequestProps> {
        if (typeof accessId !== 'undefined') {
            return await sendPutRequest(`/access/${accessId}/edit-profile/`, data);
        }
        return await sendPutRequest('/current-user/', data);
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-83108198-ada2-4434-9459-612bf9054b16 | Link}
     * @Description Updates only user's image
     */
    async updateUserImage({ image, accessId }: UpdateUserImageRequest): Promise<PostRequestProps> {
        const formData = new FormData();
        if (image) {
            formData.append('image', image);
        }
        if (typeof accessId !== 'undefined') {
            console.log('saving HERE: ', { accessId, formData });
            return await sendPutRequestWithFile(`/access/${accessId}/edit-profile/`, formData);
        }
        return await sendPutRequestWithFile('/current-user/', formData);
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-7b0b71e1-2157-4b0a-82dc-c4ae44fd4621 | Link}
     * @Description Get CV's for current authenticated user by page
     */
    async getCvs(page?: string): Promise<any> {
        let queryString = '';
        if (page) {
            queryString += `?page=${page}`;
        }
        const { response } = await sendGetRequest(`/cvs/${queryString}`);
        return response.data;
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-dda3184a-a4ff-490f-8f3f-8eaae8608ed4 | Link}
     */
    async getCvById(id: string): Promise<any> {
        const { response } = await sendGetRequest(`/cvs/${id}`);
        return response.data;
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-13800525-00f9-490b-bf45-d7b281cd105d | Link}
     * @Description Delete CV by ID
     */
    async deleteCvById(id: string): Promise<any> {
        const { response } = await sendPostRequest('/cvs/delete', { id });
        return response.data;
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-433b1da8-ff22-439b-a059-462e921705ed | Link}
     * @Description Get PDF for CV by ID
     */
    async getCvPDF(id: string): Promise<any> {
        return await sendGetRequest(`/cvs/pdf/${id}`);
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-1669c000-cab6-446a-ba56-2cab1ccee4df | Link}
     * @Description Get Docx document of CV
     */
    async getCvDocX(id: string): Promise<any> {
        return await sendGetRequest(`/cvs/docx/${id}`);
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-62b999f5-ed6b-4599-9d9a-231d6ea27f24 | Link}
     * @Description Filter CV's by keyword
     */
    async searchCv(word: string): Promise<any> {
        return await sendGetRequest(`/cvs/search/${word}`);
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-935501f0-daf4-415e-a864-291302c69e9d | Link}
     * @Description Gets CV's temporary link to be opened from anyone
     */
    async getCvTemporaryLink(id: string): Promise<any> {
        return await sendGetRequest(`/cvs/search/${id}`);
    },

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-9a4e83d9-467c-4f13-a44c-519e948c61fc | Link}
     * @Description Sends the docx or PDF version of your PDF over email to specifed email
     */
    async SendCvOverEmail(data: SendEmailProps): Promise<any> {
        return await sendPostRequest('/send-email', data);
    },
};
