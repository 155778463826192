import {
    useContext, useEffect, useReducer, useRef, 
} from 'react';
import { StoreContext } from '../../../store.context';
import { CurrentUser, UserGroup } from '../ts/auth_interfaces';

interface State<T> {
    user?: T | null;
    error?: Error;
    isUserLoading?: boolean;
    userRole: UserGroup | null;
}

type Cache<T> = { obj: CurrentUser | null };

// discriminated union type
type Action<T> =
    | { type: 'loading'; payload: boolean }
    | { type: 'fetched'; payload: CurrentUser }
    | { type: 'error'; payload: Error }

function useUser<T = CurrentUser>(): State<T> {
    // Used to prevent state update if the component is unmounted
    const cache = useRef<Cache<T>>({ obj: null });
    // const navigate = useNavigate();

    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef<boolean>(false);

    const { authStore } = useContext(StoreContext);

    const initialState: State<T> = {
        userRole: null,
        isUserLoading: true,
        error: undefined,
        user: null,
    };

    // Keep state logic separated
    const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
        switch (action.type) {
        case 'loading':
            return { ...initialState, isUserLoading: action.payload };
        case 'fetched':
            if (!action.payload.is_superuser && (action.payload.groups && typeof action.payload.groups[0] === 'undefined')) {
                localStorage.removeItem('access_token');
                // navigate('/');
                console.error('NO USER GROUP');
                // notifications.error(UNEXPECTED_ERROR_MESSAGE);
                return { ...initialState };
            }

            if (action.payload.is_superuser) {
                return {
                    ...initialState,
                    user: action.payload as T,
                    // @ts.ignore
                    userRole: 99,
                    isUserLoading: false,
                };
            }
            return {
                ...initialState,
                user: action.payload as T,
                userRole: action.payload.groups[0],
                isUserLoading: false,
            };
        case 'error':
            return { ...initialState, error: action.payload, userRole: null };
        default:
            return state;
        }
    };

    const [state, dispatch] = useReducer(fetchReducer, initialState);

    useEffect(() => {
        // console.count('Use effect ');
        // If a cache exists, return it
        if (cache.current.obj) {
            dispatch({ type: 'fetched', payload: cache.current.obj });
            dispatch({ type: 'loading', payload: false });
            return () => {
                cancelRequest.current = true;
            };
        }
        cancelRequest.current = false;

        const fetchUser = async () => {
            if (cancelRequest.current) {
                console.info('Canceling current request. (1)');
            }

            dispatch({ type: 'loading', payload: true });

            try {
                const user = await authStore.getUser();
                dispatch({ type: 'loading', payload: false });
                if (!user) {
                    console.warn('no user ,returning (1)');
                    return;
                }

                cache.current.obj = user;
                dispatch({ type: 'fetched', payload: user });
            } catch (error) {
                dispatch({ type: 'loading', payload: false });
                if (cancelRequest.current) {
                    console.warn('%cCanceling request to fetch user, the component is probably unmounted.', 'color:gray;');
                    return;
                }
                console.error('%cError while fetching user', 'color:gray;', error);
                dispatch({ type: 'error', payload: error as Error });
            }
        };

        fetchUser();

        // Use the cleanup function for avoiding a possibly...
        // ...state update after the component was unmounted
        return () => {
            cancelRequest.current = true;
        };
    }, []);

    return state;
}

export default useUser;
