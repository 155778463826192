import React, {
    useContext, useEffect, useMemo, useState, 
} from 'react';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import useUser from '../authentication/hooks/useUser';
import UserHelper from '../authentication/services/UserHelper';
import { StoreContext } from '../../store.context';
import InfoAlert from '../../components/alerts/InfoAlert';
import FunctionBox from '../functions/components/FunctionBox';
import FunctionService from '../functions/services/function.service';
import { FunctionItemDo } from '../functions/ts/function.interface';
import LoadingFunctionBox from '../functions/components/LoadingFunctionBox';
import ResumeAddFunction from '../functions/components/resumeAddFunction';
import ImageHolder from '../../components/user/ImageHolder';
import Layout from '../../components/layout/Layout';
import { rolesThatCanAccessHomepage } from '../../config/routes';
import Login from '../authentication/screens/Login';
import LoadingOverlay from '../../components/layout/LoadingOverlay';

function HomeComponent() {
    const {
        user, error, userRole, isUserLoading,
    } = useUser();
    const navigate = useNavigate();
    const { authStore } = useContext(StoreContext);
    const userInitials = useMemo(() => UserHelper.getUserInitials(user?.full_name ?? 'C V'), [user?.full_name]);

    const [isFunctionsLoading, setIsFunctionsLoading] = useState<boolean>(false);
    const [functions, setFunctions] = useState<FunctionItemDo[]>([]);

    useEffect(() => {
        if (!user || !userRole) {
            return;
        }
        if (!user.is_superuser && !rolesThatCanAccessHomepage.includes(userRole)) {
            console.warn('Redirecting to /candidates');
            navigate('/candidates');
            return;
        }
        setIsFunctionsLoading(true);
        FunctionService.getForUser().then((response) => {
            setFunctions(response);
            setIsFunctionsLoading(false);
        });
    }, [user]);

    const onFunctionEditClick = (functionItemId: string) => {
        navigate(`/function/${functionItemId}/edit`);
    };

    if (isUserLoading) {
        return <LoadingOverlay />;
    }
    //
    if (!user) {
        return <Login />;
    }

    return (
        <Layout>
            <div className="content line">
                <div className="d-flex d-md-flex justify-content-center justify-content-md-start mb-5">
                    <ImageHolder
                        image={user.image}
                        name={user.full_name}
                        className="align-items-center d-flex justify-content-center ovalProf"
                        initialsClassName="align-items-center d-flex justify-content-center ovalProf"
                    />
                    <div className="d-flex flex-column justify-content-center ms-4">
                        <p className="darkGrey mb-2 profileName">{user.full_name}</p>
                        <p className="mb-0 profileNumber">{user.user}</p>
                    </div>
                </div>

                <div>
                    <div
                        className="me-0 me-sm-auto ms-sm-auto pe-0 pe-sm-3 ps-0 ps-lg-4 ps-sm-3 ps-xl-3 ps-xxl-2 row"
                    >
                        <div
                            className="col-12 col-lg-4 col-md-4 col-sm-5 col-xl-auto d-flex d-lg-flex justify-content-center justify-content-lg-start mb-md-0 offset-lg-0"
                        >
                            <div
                                className="d-flex flex-column justify-content-md-center personalDetails personalDetailsDiv1"
                            />
                            <div
                                className="d-flex flex-column justify-content-md-center personalDetails personalDetailsDiv2"
                            />
                            <div className="align-items-center d-flex flex-column personalDet personalDetails">
                                <div className="mb-5 mt-auto">
                                    <p
                                        style={{ fontWeight: '600' }}
                                        className="bold darkGrey mb-1 text-center"
                                    >
                                        {user.full_name}
                                    </p>
                                    <p className="defaultGreyText">{user.user}</p>
                                </div>
                                <div className="mb-auto text-center">
                                    <Link to="/resume" className="editBtnFull">
                                        Edit
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 col-lg-5 col-md-6 col-sm-6 col-xl-4 offset-lg-0 offset-md-1 pe-0"
                        >
                            <InfoAlert
                                identifier="personal_details_info"
                                text="This information will be used as basis for all versions of your resume"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <hr className="horizontalLine mb-4 mt-5" />
                </div>
                <div>
                    <div className="me-0 me-lg-auto ms-0 ms-lg-auto pe-0 ps-0 row">

                        {functions.map((functionItem) => (
                            <FunctionBox
                                title={functionItem.name}
                                subtitle={functionItem.summary}
                                onEditClick={() => onFunctionEditClick(functionItem.id)}
                                key={functionItem.id}
                            />
                        ))}

                        {isFunctionsLoading ? (
                            <>
                                <LoadingFunctionBox />
                                <LoadingFunctionBox />
                            </>
                        ) : (
                            <ResumeAddFunction />
                        )}

                        <div
                            className="align-items-center col-12 col-lg-5 col-md-auto col-xl-auto d-flex justify-content-center mb-5 offset-lg-0"
                        >
                            <InfoAlert
                                identifier="functions_summ"
                                text="Here you can create variants of your resume for specific roles."
                            />
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
}

const Homepage = observer(HomeComponent);
export default Homepage;