import React, { useEffect, useState } from 'react';
import LoginLayout from '../../../components/layout/LoginLayout';
import RequestAccesService from '../services/request-access.service';
import notifications from '../../../utils/notifications';
import { GENERAL_VALIDATION_FAIL_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from '../../../utils/constants';
import { SUCCESS } from '../../../dto/constants.dto';
import PrimaryButton from '../../../components/common/PrimaryButton';
import useKeyPress from '../../../hooks/useKeyPress';


interface Props {
    className?: string;
}

function RequestAcces({ className = '' }: Props) {
    const [email, setEmail] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState({ email: '' });
    const onEnterClick = useKeyPress('Enter');

    const onSubmit = () => {
        setError({ email: '' });
        if (!email.length) {
            notifications.warning(GENERAL_VALIDATION_FAIL_MESSAGE);
            return;
        }
        setIsLoading(true);
        RequestAccesService.sendRequest(email).then((res) => {
            setIsLoading(false);
            const { response, status } = res;
            if (status === SUCCESS) {
                notifications.success('Access successfully requested.');
                setEmail('');
                return;
            }
            if (response.data.email) {
                setError({ email: response.data.email });
            } else if (response.data) {
                notifications.error(response.data);
            } else {
                notifications.error(UNEXPECTED_ERROR_MESSAGE);
            }
        });
    };

    useEffect(() => {
        if (onEnterClick && email.length) {
            onSubmit();
        }
    }, [onEnterClick]);

    return (
        <LoginLayout>
            <div className="bold loginContainer">
                <div className="align-items-center d-flex justify-content-center mb-3 ovalProf">
                    <i className="fa-unlock-alt fas" />
                </div>
                <h1 className="loginHeading">Request Access</h1>

                <h3 className="subtitle">
                    Enter your email for
                    <br />
                    which you want access
                </h3>
                <div className="d-flex flex-column mb-4">
                    <label className="loginLabel required mb-2">Email</label>
                    <input
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="your@email.com"
                        type="email"
                        value={email}
                        name="email"
                        className="loginInput"
                    />
                    {error.email ? (
                        <small className="error-text">{error.email}</small>
                    ) : null}
                </div>
                <PrimaryButton
                    isLoading={isLoading}
                    className="loginBtn"
                    onClick={() => onSubmit()}
                    type="button"
                    text="Request access"
                />
            </div>
        </LoginLayout>
    );
}

export default RequestAcces;