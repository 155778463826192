import React, {
    useEffect, useMemo, useRef, useState, 
} from 'react';
import useKeyPress from '../../../hooks/useKeyPress';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import TableRowAction from '../../../components/table/TableRowAction';
import { Candidate } from '../../candidates/ts/candidates.interface';
import ImageHolder from '../../../components/user/ImageHolder';
import PrimaryButton from '../../../components/common/PrimaryButton';
import CompanyService from '../services/company.service';
import { CompanyDto } from '../ts/comapnies-index.interface';
import { AvailableCandidate, AvailableManager } from '../ts/company-service.interface';


interface ItemProps {
    onAddClick: () => void,
    isChecked: boolean,
    item: AvailableCandidate, // can also be a manager
}

function CandidateManager({ onAddClick, item, isChecked = false }: ItemProps) {
    return (
        <div>
            <div className="modalGrid">
                <div className="p-2 pb-3 pe-2 ps-2 pt-3 usersGridItem">
                    <p className="darkGrey mb-0 profileNumber">
                        {item.user_id}
                    </p>
                </div>
                <div className="p-2 pb-3 pe-2 ps-2 pt-3 usersGridItem">
                    <div className="align-items-center d-flex justify-content-start">
                        <ImageHolder
                            image={item.image}
                            name={item.full_name}
                            initialsClassName="initials-holder companyImgOval companyImgOvalInitials me-4"
                            className="companyImgOval me-4"
                        />
                        <span className="bold peopleFullname">
                            {
                                (item.full_name.length > 0) ? (
                                    item.full_name
                                ) : (
                                    '-'
                                )
                            }
                        </span>
                    </div>
                </div>
                <TableRowAction
                    onEditClick={onAddClick}
                    buttonText={(isChecked ? 'Remove' : 'Add')}
                    className="pb-3 pe-2 ps-2 pt-3"
                    buttonClassName={(isChecked ? 'removeBtn' : '')}
                />
            </div>
            <hr />
        </div>
    );
}


interface Props {
    isOpen?: boolean;
    type?: 'candidates' | 'managers';
    onClose?: () => void,
    onSubmit?: () => void,
    company: CompanyDto,
    addedUsersUpdated?: (user_id: number) => void,
}

function CompanyModal({
    isOpen = false,
    onClose = () => null,
    onSubmit = () => null,
    type,
    addedUsersUpdated = () => null,
    company,
}: Props) {
    const modalRef = useRef(null);

    const [isCandidatesLoading, setIsCandidatesLoading] = useState<boolean>(false);
    const [isManagersLoading, setIsManagersLoading] = useState<boolean>(false);

    const isClickedOutside = useOnClickOutside(modalRef);
    const isEscapeClicked = useKeyPress('Escape');

    const [addedUsers, setAddedUsers] = useState<number[]>([]);

    const [candidates, setCandidates] = useState<AvailableCandidate[]>([]);
    const [managers, setManagers] = useState<AvailableManager[]>([]);

    const colName = useMemo(() => (type === 'managers' ? 'Manager' : 'Candidate'), [type]);

    const closePrompt = () => onClose();

    const getManagers = async () => {
        if (type !== 'managers') {
            return;
        }
        setIsManagersLoading(true);
        CompanyService.getAvailableManagers(company.id).then((managersRes) => {
            setIsManagersLoading(false);
            setManagers(managersRes);
        });
    };

    const getCandidates = async () => {
        if (type !== 'candidates') {
            return;
        }
        setIsCandidatesLoading(true);
        CompanyService.getAvailableCandidates(company.id).then((candidatesRes) => {
            setIsCandidatesLoading(false);
            setCandidates(candidatesRes);
        });
    };

    useEffect(() => {
        if (type === 'candidates') {
            getCandidates();
        } else if (type === 'managers') {
            getManagers();
        } else {
            console.warn('Unsupported type: ', type);
        }
    }, [type]);

    useEffect(() => {
        if (isEscapeClicked && isOpen) {
            closePrompt();
        }
    }, [isEscapeClicked]);


    const closeModal = () => {
        closePrompt();
    };

    useEffect(() => {
        if (isClickedOutside && isOpen) {
            closeModal();
        }
    }, [isClickedOutside]);

    const addAction = (item: AvailableCandidate) => {
        const arr = [...addedUsers];
        if (arr.includes(item.user_id)) {
            const index = arr.indexOf(item.user_id);
            if (index !== -1) arr.splice(index, 1);
        } else {
            arr.push(item.user_id);
        }
        setAddedUsers(arr);
        addedUsersUpdated(item.user_id);
    };

    const onSubmitAction = () => {
        onSubmit();
        closePrompt();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={`customModalContainer companies ${isOpen ? 'd-flex' : 'd-none'}`}>
            <div ref={modalRef}>
                <div className="greybg p-3">
                    <h3>{`${colName}s`}</h3>
                    <hr className="horizontalLine" />

                    <button type="button" onClick={() => closeModal()} className="close-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            className="xmark cursor-pointer"
                        >
                            <path
                                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                            />
                        </svg>
                    </button>

                    <div className="greybg modalGrid">
                        <div className="pe-2 ps-2 usersGridLabel">
                            <p className="mb-0 profileNumber">ID</p>
                        </div>
                        <div className="pe-2 ps-2 usersGridLabel">
                            <p className="mb-0 profileNumber">{colName}</p>
                        </div>
                        {/* <div className="pe-2 ps-2 usersGridLabel"> */}
                        {/*    <p className="mb-0 profileNumber">Add</p> */}
                        {/* </div> */}
                    </div>

                    {type === 'candidates' ? (
                        <>
                            {candidates?.map((item) => (
                                <CandidateManager
                                    key={item.id}
                                    item={item}
                                    isChecked={addedUsers.includes(item.user_id)}
                                    onAddClick={() => addAction(item)}
                                />
                            ))}
                            {candidates.length === 0 && !isCandidatesLoading ? (
                                <>
                                    <hr className="horizontalLine" />
                                    <div className="p-3"><p>No candidates available for selection.</p></div>
                                </>
                            ) : null}
                        </>
                    ) : null}

                    {type === 'managers' ? (
                        <>
                            {managers?.map((item) => (
                                <CandidateManager
                                    key={item.id}
                                    item={item}
                                    isChecked={addedUsers.includes(item.user_id)}
                                    onAddClick={() => addAction(item)}
                                />
                            ))}
                            {managers.length === 0 && !isManagersLoading ? (
                                <>
                                    <hr className="horizontalLine" />
                                    <div className="p-3"><p>No managers available for selection.</p></div>
                                </>
                            ) : null}
                        </>
                    ) : null}

                    <hr className="horizontalLine" />
                    <div className="footer">
                        <PrimaryButton
                            text="Save & Close"
                            onClick={() => onSubmitAction()}
                            className="cancelBtn  detailsBtn"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyModal;