import { sendGetRequest } from '../../../lib/axios/request';
import {
    DownloadDocxProps, DownloadPdfProps, MagicLinkDto, MagicLinkProps, 
} from '../ts/document.interface';

const DocumentService = {

    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-098bf0fa-4388-4676-bdb8-949b5ca2bca3 | Link}
     */
    async getMagicLink({ functionId, companyId }: MagicLinkProps): Promise<MagicLinkDto> {
        return await sendGetRequest(`/magic-link/get/${functionId}/${companyId}/`);
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-ee9552ca-80c1-4fde-989e-82c12f25eb70 | Link}
     */
    async downloadDocx({ functionId, companyId }: DownloadDocxProps): Promise<MagicLinkDto> {
        return await sendGetRequest(`/cv/docx/${functionId}/${companyId}/`);
    },


    /**
     * Postman request: {@link https://morgan-code.postman.co/workspace/CV-maker~d12bea58-09b6-4366-be47-7ea4fc67d0df/request/16173878-db5a1bc7-8fa8-422b-9a75-0201e32ce1ad | Link}
     */
    async downloadPdf({ functionId, companyId }: DownloadPdfProps): Promise<MagicLinkDto> {
        return await sendGetRequest(`/cv/pdf/${functionId}/${companyId}/`);
    },

};

export default DocumentService;