import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LoginLayout from '../../../components/layout/LoginLayout';
import ForgotPasswordService from '../services/forgot-password.service';
import notifications from '../../../utils/notifications';
import { GENERAL_SUCCESS_MESSAGE, GENERAL_VALIDATION_FAIL_MESSAGE } from '../../../utils/constants';
import { SUCCESS } from '../../../dto/constants.dto';
import PrimaryButton from '../../../components/common/PrimaryButton';
import useKeyPress from '../../../hooks/useKeyPress';


function ResetPassword() {
    const [token, setToken] = useState<string>('');
    const [password, setNewPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState({ password: '' });
    const onEnterClick = useKeyPress('Enter');

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const tmpToken = searchParams.get('token');
        if (!tmpToken) {
            notifications.warning('Invalid token provided. Please request new magic link.');
            navigate('/forgot-password');
            return;
        }
        setToken(tmpToken);
    }, []);

    const onSubmit = () => {
        setError({ password: '' });
        if (!password.length) {
            notifications.warning(GENERAL_VALIDATION_FAIL_MESSAGE);
            return;
        }
        setIsLoading(true);
        ForgotPasswordService.changePassword({ token, password }).then((res) => {
            setIsLoading(false);
            const { response, status } = res;
            if (status === SUCCESS) {
                notifications.success(GENERAL_SUCCESS_MESSAGE);
                navigate('/');
                return;
            }
            if (response.data.password) {
                setError({ password: response.data.password[0] });
            } else {
                notifications.warning('Invalid token. Please request a new magic link.');
            }
        });
    };

    useEffect(() => {
        if (onEnterClick && password.length) {
            onSubmit();
        }
    }, [onEnterClick]);

    return (
        <LoginLayout>
            <div className="bold loginContainer">
                <div className="align-items-center d-flex justify-content-center mb-3 ovalProf">
                    <i className="fa-unlock-alt fas" />
                </div>
                <h1 className="loginHeading">Password reset</h1>

                <h3 className="subtitle">
                    Enter you new password
                </h3>
                <div className="d-flex flex-column mb-4">
                    <label className="loginLabel required mb-2">New Password</label>
                    <input
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                        type="password"
                        value={password}
                        name="password"
                        className="loginInput"
                    />
                    {error.password ? (
                        <small className="error-text">{error.password}</small>
                    ) : null}
                </div>
                <PrimaryButton
                    isLoading={isLoading}
                    className="loginBtn"
                    onClick={() => onSubmit()}
                    type="button"
                    text="Change Password"
                />
                <Link to="/forgot-password" className="forgotPassword">Need new token?</Link>
            </div>
        </LoginLayout>
    );
}

export default ResetPassword;