// Components
import { FunctionComponent } from 'react';
import Homepage from '../features/homepage';
import Resume from '../features/resume';
import Companies from '../features/companies';
import {
    CANDIDATE_ID,
    COMPANY_MANAGER_ID,
    CurrentUser,
    SALES_MANAGER_ID,
    UserGroup,
} from '../features/authentication/ts/auth_interfaces';
import CreateFunction from '../features/functions/components/CreateFunction';
import CompaniesForm from '../features/companies/screens/CompaniesForm';
import CandidatesForm from '../features/candidates';
import CandidatesIndex from '../features/candidates/components/CandidatesIndex';
import AccessIndex from '../features/access';
import AccessForm from '../features/access/components/AccessForm';
import ResumeForm from '../features/resume/components/ResumeForm';
import CandidatesShow from '../features/candidates/components/CandidatesShow';
import CompaniesEdit from '../features/companies/screens/CompaniesEdit';
import ForgotPassword from '../features/forgot-password/screens/ForgotPassword';
import ResetPassword from '../features/forgot-password/screens/ResetPassword';
import RequestAccess from '../features/request-access/screens/RequestAccess';
import Functions from '../features/candidates/components/Functions';


export interface AvailableRoute {
    component: FunctionComponent,
    path: string,
    title: string,
    exact: boolean,
    forceRefresh?: boolean
    permission: UserGroup[] | null,
}

export type AvailableRoutes = Array<AvailableRoute>;


export const rolesThatCanAccessHomepage: UserGroup[] = [CANDIDATE_ID];
export const rolesThatCanAccessResume: UserGroup[] = [CANDIDATE_ID, COMPANY_MANAGER_ID];
export const rolesThatCanAccessCandidates: UserGroup[] = [SALES_MANAGER_ID, COMPANY_MANAGER_ID, CANDIDATE_ID];
export const rolesThatCanAccessFunctions: UserGroup[] = [CANDIDATE_ID];
export const rolesThatCanAccessCompanies: UserGroup[] = []; // only super admin
export const rolesThatCanAccessAccess: UserGroup[] = [COMPANY_MANAGER_ID]; // only super admin


export function getAllowedRoutes(routes: any[], userRole: UserGroup | null, user: CurrentUser | null | undefined) {
    if (user?.is_superuser) {
        return routes;
    }
    return routes.filter(({ permission, component, path }) => {
        if (permission === null) {
            return true;
        }
        return permission.includes(userRole);
    });
}


// https://javascript.plainenglish.io/role-based-authorization-role-based-access-control-v-2-in-react-js-cb958e338f4b
const routes: AvailableRoutes = [
    {
        component: Homepage,
        path: '/',
        title: 'Cv Maker',
        exact: true,
        permission: null,
    },
    {
        component: ResumeForm,
        path: '/resume',
        title: 'Resume',
        exact: true,
        forceRefresh: true,
        permission: rolesThatCanAccessResume,
    },
    {
        component: ResumeForm,
        path: '/resume/:accessId/edit',
        title: 'Resume Edit',
        exact: true,
        forceRefresh: true,
        permission: rolesThatCanAccessAccess,
    },
    {
        component: CandidatesIndex,
        path: '/candidates',
        title: 'Candidates',
        exact: true,
        permission: rolesThatCanAccessCandidates,
    },
    {
        component: Functions,
        path: '/functions',
        title: 'Functions',
        exact: true,
        permission: rolesThatCanAccessFunctions,
    },
    {
        component: CandidatesForm,
        path: '/candidates/create',
        title: 'Candidates',
        exact: true,
        permission: rolesThatCanAccessCandidates,
    },
    {
        component: CandidatesShow,
        path: '/candidates/:candidateId',
        title: 'Candidates',
        exact: true,
        permission: rolesThatCanAccessCandidates,
    },
    {
        component: Companies,
        path: '/companies',
        title: 'Companies',
        exact: true,
        permission: rolesThatCanAccessCompanies,
    },
    {
        component: CompaniesEdit,
        path: '/companies/:companyId/edit',
        title: 'Companies',
        exact: true,
        permission: rolesThatCanAccessCompanies,
    },
    {
        component: CompaniesForm,
        path: '/companies/create',
        title: 'Companies',
        exact: true,
        permission: rolesThatCanAccessCompanies,
    },
    {
        component: AccessIndex,
        path: '/access',
        title: 'Access',
        exact: true,
        permission: rolesThatCanAccessAccess,
    },
    {
        component: AccessForm,
        path: '/access/:accessId/edit',
        title: 'Access',
        exact: true,
        permission: rolesThatCanAccessAccess,
    },
    {
        component: CreateFunction,
        path: '/function/create',
        title: 'Create Function',
        exact: true,
        permission: rolesThatCanAccessResume,
    },
    {
        component: CreateFunction,
        path: '/function/:functionId/edit',
        title: 'Create Function',
        exact: true,
        permission: rolesThatCanAccessResume,
    },
    {
        component: ForgotPassword,
        path: '/forgot-password',
        title: 'Forgot Password?',
        exact: true,
        permission: null,
    },
    {
        component: ResetPassword,
        path: '/reset-password',
        title: 'Reset Password',
        exact: true,
        permission: null,
    },
    {
        component: RequestAccess,
        path: '/request-access',
        title: 'Request Access',
        exact: true,
        permission: null,
    },
];

export default routes;
